<!--
 * @Author: magic
 * @Date: 2021-06-25 15:50:29
 * @LastEditors: magic
 * @LastEditTime: 2022-04-07 15:05:29
 * @Description: Do not edit
 * @FilePath: /www.bmob.cn/src/components/PromptMessage/index.vue
-->
<!-- 写一个PromptMessage的组件，并全局注册
      插槽，可提供多行的提示信息 -->
<template>
  <el-tooltip effect="dark" placement="top-start">
    <span slot="content">
      {{ message }}
    </span>
    <p class="d">
      <span>{{ label }}</span>
      <span class="t-hint">{{ attrType }}</span>
      <span v-if="showIcon" class="glyphicon glyphicon-question-sign" />
    </p>
    <!-- <i class="el-icon-question" style="color:#409eff;margin-left:1px;font-size:10px;"></i> -->
  </el-tooltip>
</template>
<script>
export default {
  props: {
    label: String,
    attrType: String,
    message: String,
    showIcon: Boolean
  }
}
</script>
<style type="text/css">
.d {
  display: inline;
  width: 100%;
  color: #828282;
}
.t-hint {
  color: #999;
  margin-left: 4px;
}
</style>
