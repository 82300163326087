<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script src="https://cdn.staticfile.org/element-ui/2.15.1/index.js"></script>
<script>
export default {
  name: 'App',
  mounted(){
    console.log('app monted!!!!!!!!')
  }
}
</script>
