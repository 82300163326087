/*
 * @Author: magic
 * @Date: 2021-09-27 11:57:49
 * @LastEditors: magic
 * @LastEditTime: 2022-05-09 11:55:45
 * @Description: Do not edit
 * @FilePath: /www.bmob.cn/src/main.js
 */
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

// import Cookies from 'js-cookie'
// import Element from 'element-ui'
// import './styles/element-variables.scss'

import * as filters from './filters' // global filters

import './styles/bootstrap.min.css'
import './styles/responsive-nav.min.css'

import './styles/frontend/global.css'
import './styles/frontend/base1.scss' // base


import './permission'
import 'babel-polyfill'

Vue.config.productionTip = false

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// 这是给ElementUI组件库组件设置默认参数（cdn加速和生产环境配置有区别）
Vue.prototype.$ELEMENT = {
  size: 'small'
}
// Vue.use(Element, {
//   size: Cookies.get('size') || 'small'
// })

import PromptMessage from './components/PromptMessage/index'
Vue.component('prompt-message', PromptMessage)

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})

Vue.prototype.$EventBus = new Vue()

import { setUserInfo, getUserInfo } from '@/api/user'
Vue.prototype.$setUserInfo = setUserInfo
Vue.prototype.$getUserInfo = getUserInfo
Vue.prototype.$getUserId = function() {
  return Number(getUserInfo().id)
}
