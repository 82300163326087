<template>
  <div class="appLayout" :style="isFull?'padding-left:0':''">
    <el-alert v-for="(item, index) in alertList" :key="item.id" type="success" effect="dark" @close="onCloseAlert(item, index)">
      <div slot="title" @click="onClickAlert(item, index)">
        <span :class="item.link ? 't-link':'' " v-html="item.content" />
      </div>
    </el-alert>
    <div v-for="(item) in alertList" :key="item.id" class="v-alert">{{ item.content }}</div>

    <appLeft />
    <appSidebar :alert-list="alertList" />

    <div class="main-right container">
      <router-view :key="$route.path" />
      <appFooter />
    </div>
    <div class="alert alert-success alert-dismissable" style="display: none;">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
      <div id="msg_success" />
    </div>
    <div class="alert alert-danger alert-dismissable" style="display: none;">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
      <div id="msg_error" />
    </div>
  </div>
</template>
<script>
import appLeft from '@/components/appLeft'
import appSidebar from '@/components/appSidebar'
import appFooter from '@/components/appFooter'
import { fetchNotifyList, markReadNotify } from '@/api/backend'
import { getApp } from '@/api/app'

import '@/utils/bootstrap.min.js'

export default {
  name: 'App',
  components: { appLeft, appFooter, appSidebar },
  data() {
    return {
      alertList: [],
      isFull: this.$route.path.includes('/browser') && this.$route.query.full
    }
  },
  created() {
    // require('@/styles/base.css')
    // require('@/styles/global.css')
    // require('@/styles/datalist.css')
    // require('@/styles/responsive-nav.css')
    // require('@/styles/pay.css')
  },
  mounted() {
    console.log('app monted')
  

    $('button.close').on('click', function() {
      $(this).parent('.alert').hide()
    })

    // eslint-disable-next-line no-undef
    responsiveNav('#nav') // 响应式导航

    fetchNotifyList({
      query: 'IsShowIndex:1,IsReaded:0',
      limit: 1
    }).then(res => {
      if (res && res.data && res.data.results && res.data.results.length) {
        this.alertList = res.data.results

        const alert = res.data.results[0]
        if (alert.notifyType == 2 && alert.content.indexOf('>>升级套餐') > -1 && alert.link.lastIndexOf('applevel/') > -1) {
          const appId = alert.link.substring(alert.link.lastIndexOf('applevel/') + 9)
          getApp(appId).then((app) => {
            app = app.data
            const appLevelDeadtime = app.appLevelDeadtime * 1000
            if (appLevelDeadtime > ((new Date()).getTime() + 1000 * 60 * 60 * 24 * 28)) {
              console.log('到期时间还有4周以上')
              this.onCloseAlert(alert, 0)
            }
          })
        }
      } else {
        this.alertList = []
      }
    })
  },
  methods: {
    onClickAlert(item, index) {
      if (item.link) {
        markReadNotify([item.id]).then(res => {
          this.alertList.splice(index, 1)
          this.$EventBus.$emit('notifyNumChange')
        })
        window.location = item.link
      }
    },
    onCloseAlert(item, index) {
      markReadNotify([item.id]).then(res => {
        this.alertList.splice(index, 1)
        this.$EventBus.$emit('notifyNumChange')
      })
    }
  }
}
</script>
<style scoped>
@import '~@/styles/base.css';
@import '~@/styles/global.css';
@import '~@/styles/datalist.css';
@import '~@/styles/responsive-nav.css';
@import '~@/styles/pay.css';
</style>
<style>
.el-table th {
    background-color: #f3f7ff !important;
}
</style>
