import request from '@/utils/request'
import { setToken } from '@/utils/auth'
import { utf8to16, base64decode, base64encode, utf16to8 } from '@/utils/index'
import { Message } from 'element-ui'
import store from '@/store'
import { parseTime } from '@/utils/index'

export function setUserInfo(data) {
  setToken(data.id)
  localStorage.setItem(
    'userInfo',
    base64encode(
      utf16to8(
        JSON.stringify({
          id: data.id,
          openId: data.openId,
          name: data.name,
          mail: data.mail,
          qq: data.qq,
          weixin: data.weixin,
          tel: data.tel
        })
      )
    )
  )
}

export function setLeftAiCount(uid, count) {
  console.log('setLeftAiCount', uid, count)
  const today = parseTime(new Date(), '{y}-{m}-{d}')
  const info = { }
  info[today] = count
  localStorage.setItem('lac_' + uid, base64encode(
    utf16to8(
      JSON.stringify(info)
    )
  ))
}
export function getLeftAiCount(uid) {
  let info = localStorage.getItem('lac_' + uid)
  if (!info) return uid == '_tourist' ? 10 : 40
  info = JSON.parse(utf8to16(base64decode(info)))
  console.log('getLeftAiCount', uid, info)
  const today = parseTime(new Date(), '{y}-{m}-{d}')
  if (info[today]) return (Number(info[today]) || 0)
  else return uid == '_tourist' ? 10 : 40
}

export function getUserInfo(noLogin) {
  try {
    let info = localStorage.getItem('userInfo')
    if (!info) {
      if (!noLogin) {
        showLogin()
      }
      return null
    }
    info = JSON.parse(utf8to16(base64decode(info)))
    return info
  } catch (err) {
    console.error('getUserInfo err', err)
    if (!noLogin) { showLogin() }
  }
}

function showLogin() {
  Message({
    message: '登录状态过期啦，正在跳转登录',
    type: 'error',
    duration: 5 * 1000
  })
  // MessageBox.confirm("", "登录状态过期啦", {
  //   confirmButtonText: "重新登录",
  //   cancelButtonText: "回到首页",
  //   type: "warning",
  // })
  //   .then(() => {
  store.dispatch('user/logout').then(() => {
    location.reload()
  })
  // })
  // .catch(() => {
  //   store.dispatch("user/logout").then(() => {
  //     window.location.href = "/";
  //   });
  // });
}

// {
// "email": "string",
// "fromPath": "string",
// "password": "string",
// "username": "string"
// }
export function register(data) {
  const code = window.sessionStorage.getItem('invite_code')
  if (code) {
    data.invite_code = code
  }
  return request({
    url: '/front/user/register',
    method: 'post',
    data
  })
}

// "password": "string",
// "username": "string"
export function login(params) {
  return request({
    url: '/front/user/login',
    method: 'get',
    params
  })
}

export function getPwReset(params) {
  return request({
    url: '/front/user/requestPasswordReset',
    method: 'get',
    params
  })
}

export function postPwReset(params) {
  return request({
    url: '/front/user/requestPasswordReset',
    method: 'post',
    params
  })
}

export function requestEmailVerify(params) {
  return request({
    url: '/front/user/requestEmailVerify',
    method: 'get',
    params
  })
}

export function autoLogin(params) {
  params.password = base64encode(params.password)
  return request({
    url: '/front/userfromold/login',
    method: 'get',
    params
  })
}

// 获取登录微信二维码
export function getWechatCode() {
  return request({
    url: '/front/user/wechat_code',
    method: 'get'
  })
}
// 检查登录状态
export function checkWxLogin(EventKey) {
  return request({
    url: '/front/user/check_login',
    method: 'get',
    params: {
      EventKey
    }
  })
}

export function logout() {
  return request({
    url: '/front/user/logout',
    method: 'get'
  })
}

export function getUser(uid) {
  return request({
    url: '/front/user/' + uid,
    method: 'get'
  })
}
export function updateUser(uid, params) {
  return request({
    url: '/front/user/' + uid,
    method: 'put',
    params
  })
}
export function delUser(uid) {
  return request({
    url: '/front/user/' + uid,
    method: 'delete'
  })
}

export function sendSms(phone) {
  return request({
    url: '/front/user/send_sms',
    method: 'get',
    params: { phone }
  })
}
export function sendValid(phone, code) {
  return request({
    url: '/front/user/send_valid',
    method: 'get',
    params: { phone, code }
  })
}

export function getCaptcha() {
  return request({
    url: '/front/user/get_captcha',
    method: 'get',
    params: { width: 100, height: 42 }
  })
}
export function verifyCaptcha(captcha_code, captcha_token) {
  return request({
    url: '/front/user/verify_captcha',
    method: 'get',
    params: { captcha_code, captcha_token }
  })
}

export function forgetPassword(captcha_code, captcha_token, mail_addr) {
  return request({
    url: '/front/user/forget_password',
    method: 'get',
    params: { captcha_code, captcha_token, mail_addr }
  })
}

export function resetPassword(
  captcha_code,
  captcha_token,
  mail,
  code,
  reset_pass
) {
  return request({
    url: '/front/user/reset_password',
    method: 'get',
    params: {
      captcha_code,
      captcha_token,
      mail,
      code,
      reset_pass
    }
  })
}

export function resetPasswordPhone(phone, phone_code) {
  return request({
    url: '/front/user/reset_password_phone',
    method: 'get',
    params: {
      phone,
      phone_code
    }
  })
}
