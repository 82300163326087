<template>
  <div v-show="!isFull" id="nav" class="sidenav data-subnav nav-app">
    <ul>
      <li>
        <router-link to="/app/list">首页</router-link><span>
          <router-link to="/app/list">控制台首页</router-link>
        </span>
      </li>
      <li :class="$route.path.includes('/browser')?'on':''"><span>
        <router-link :to="'/app/browser/' + appId">数据</router-link>
      </span></li>

      <li :class="$route.path.includes('/mqtt')?'on':''"><span>
        <router-link :to="'/mqtt/index/' + appId">IOT</router-link>
      </span></li>
      <li :class="($route.path.includes('cloud') || $route.path.includes('Cloud')) && ( !$route.path.includes('/upgrade') )?'on':''">
        <router-link :to="'/app/cloud/' + appId">云函数</router-link>
        <!-- fixme app / func-->
      </li>
      <li :class="isOn(['filemaintain','filemanage','editfilethumbnail','editdomainacl','editipacl' , 'material','addMaterial','editMaterial'])?'on':''">
        <router-link :to="'/app/filemaintain/' + appId">素材</router-link>
      </li>
      <li :class="$route.path.includes('/appPay')?'on':''">
        <router-link :to="'/appPay/wechatlog/' + appId">收益</router-link>
      </li>
      <li :class="$route.path.includes('/sms') && !$route.path.includes('/upgrade')?'on':''">
        <router-link :to="'/sms/template/' + appId">短信</router-link>
      </li>
      <li :class="isOn(['pushmsg','pushmsganalysis','pushmsgsetting'])?'on':''">
        <router-link :to="'/app/pushmsg/' + appId">推送</router-link>
      </li>
      <li :class="$route.path.includes('/analysis')?'on':''">
        <router-link :to="'/analysis/ApiAll/' + appId">分析</router-link>
      </li>
      <li :class="isOn(['appauth','domain','upgrade','edit','setemail','backuprestore','secret','config','appwebsite','audit'])?'on':''">
        <router-link :to="'/app/secret/' + appId">设置</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appId: this.$route.params.appId,
      isFull: this.$route.path.includes('/browser') && this.$route.query.full
    }
  },
  methods: {
    isOn(arr) {
      var res = false
      var path = this.$route.path
      arr.forEach(e => {
        if (path.includes('/' + e + '/')) {
          res = true
          // console.log('path', path, 'e', e)
        }
      })
      return res
    }
  }
}
</script>
