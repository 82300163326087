import request from '@/utils/request'
import store from '@/store'

export function fetchCloudList(appId, params) {
  return request({
    url: '/cloud/code/all/' + appId,
    method: 'get',
    params
  })
}

export function fetchCloudProjectList(appId, params) {
  return request({
    url: '/cloud/code/project/' + appId,
    method: 'get',
    params
  })
}

export function createCloud(appId, funcName, data) {
  store.dispatch('data/resetCloudList')
  return request({
    url: `/cloud/code/${appId}/${funcName}`,
    method: 'post',
    data
    // {
    //   category: integer ($int64) 分类ID
    //   code: string 主键
    //   comment:  string 注释
    //   language: integer ($int64) 云端代码语言类型，1、node , 2、java, 3、python
    // }
  })
}

export function runCloud(appId, funcName, language, method, data) {
  return request({
    url: `/cloud/code/run/${appId}/${funcName}`,
    method: 'post',
    params: {
      language,
      method
    },
    data
  })
}

export function updateCloudTime(appId, data) {
  return request({
    url: '/cloud/code/time/' + appId,
    method: 'put',
    data
  })
}

export function getCloudTime(appId, id) {
  return request({
    url: '/cloud/cron_task/' + appId + '/' + id,
    method: 'get'
  })
}

export function getCloud(appId, id) {
  return request({
    url: '/cloud/code/one/' + appId + '/' + id,
    method: 'get'
  })
}

export function updateCloud(appId, funcName, data) {
  // return request({
  //   url: '/cloud/code/' + id,
  //   method: 'put',
  //   data
  // })
  return request({
    url: `/cloud/code/${appId}/${funcName}`,
    method: 'post',
    data
    // {
    //   category: integer ($int64) 分类ID
    //   code: string 主键
    //   comment:  string 注释
    //   language: integer ($int64) 云端代码语言类型，1、node , 2、java, 3、python
    // }
  })
}

export function delCloud(id, funcName) {
  store.dispatch('data/resetCloudList')
  return request({
    url: '/cloud/code/' + id + '/' + funcName,
    method: 'delete'
  })
}

// 云函数分类管理 >>>

export function fetchCloudCategoryList(params) {
  return request({
    url: '/cloud/code_category',
    method: 'get',
    params
  })
}

export function createCloudCategory(appId, name) {
  return request({
    url: `/cloud/code_category`,
    method: 'post',
    data: {
      'AppId': appId,
      'Name': name
    }
  })
}

export function getCloudCategory(id) {
  return request({
    url: '/cloud/code_category/' + id,
    method: 'get'
  })
}

export function updateCloudCategory(id, data) {
  return request({
    url: '/cloud/code_category/' + id,
    method: 'put',
    data
  })
}

export function delCloudCategory(id) {
  return request({
    url: '/cloud/code_category/' + id,
    method: 'delete'
  })
}

export function fetchCronLog(params) {
  return request({
    url: '/backend/cronLog',
    method: 'get',
    params
  })
}
