import request from "@/utils/request";

// 账户模块

// - [x] 用户信息  /v1/backend/account/info
// - [x] 实名认证  /v1/backend/account/info
// - [x] 密码修改  /v1/backend/account/info/password
export function getAccountInfo() {
    return request({
        url: "/backend/account/info",
        method: "get",
    });
}


// -d '{"user_id":"1763","user_name":"曾健生","cert_no":"310123199012301234"} ' \
export function userCertdocCertverifyPreconsult(data) {
    return request({
        url: "/front/alicert/userCertdocCertverifyPreconsult",
        method: "post",
        data,
    });
}

export function userCertdocCertverifyConsult(auth_code, verify_id, user_id) {
    return request({
        url: "/front/alicert/userCertdocCertverifyConsult",
        method: "post",
        data: { auth_code, verify_id, user_id },
    });
}

export function updateAccount(data) {
    return request({
        url: "/backend/account/info",
        method: "put",
        data,
    });
}

export function putBeian(id, data) {
    return request({
        url: `/backend/beian/${id}`,
        method: "put",
        data,
    });
}

export function postBeian(data) {
    return request({
        url: "/backend/beian",
        method: "post",
        data,
    });
}

export function getBeian(data) {
    return request({
        url: "/backend/beian",
        method: "get",
        data,
    });
}
export function getBeianH5(id) {
    return request({
        url: `backend/beian/getH5Url/${id}`,
        method: "get",
    });
}

// {"Domain":"bmobapp.com","OrganizerLicenseId":"91440113078426861T","OrganizerLicenseType":1}
export function extraceICPType(data) {
    return request({
        url: "/backend/beian/extraceICPType",
        method: "post",
        data,
    });
}
export function updateAccountMobile(mobile, code) {
    return request({
        url: "/backend/account/info/mobile",
        method: "put",
        data: {
            Mobile: mobile,
            SmsCode: code,
        },
    });
}
export function updateAccountPassword(oldPassword, newPassword, repsw) {
    return request({
        url: "/backend/account/info/password",
        method: "put",
        data: {
            ConfirmNewPassword: repsw,
            NewPassword: newPassword,
            Password: oldPassword,
        },
    });
}

// - [x] 团队管理  /v1/backend/account/team/member
export function fetchTeamList(email, limit, offset) {
    return request({
        url: "/backend/account/team/member",
        method: "get",
        params: {
            email,
            limit,
            offset,
        },
    });
}
export function getRolesTree() {
    return request({
        url: "/backend/rolesTree",
        method: "get",
        params: {
            limit: 10000,
        },
    });
}
export function getTeamMember(id, appId) {
    return request({
        url: "/backend/account/team/member/" + id,
        method: "get",
        params: {
            appId,
        },
    });
}
export function createTeamMember(appId, data) {
    return request({
        url: "/backend/account/team/member",
        method: "post",
        data,
        params: {
            appId,
        },
    });
}
export function updateTeamMember(id, appId, data) {
    return request({
        url: "/backend/account/team/member/" + id,
        method: "put",
        data,
        params: {
            appId,
        },
    });
}
export function delTeamMember(id) {
    return request({
        url: "/backend/account/team/member/" + id,
        method: "delete",
    });
}

// - [x] 通知设置  /v1/backend/account/notify/
export function updateAccountNotifyEmail(Switch) {
    return request({
        url: "/backend/account/notify/email",
        method: "put",
        data: {
            Switch,
        },
    });
}
export function updateAccountNotifySms(Switch) {
    return request({
        url: "/backend/account/notify/sms",
        method: "put",
        data: {
            Switch,
        },
    });
}

export function fetchAccountNotify() {
    return request({
        url: "/backend/account/notify",
        method: "get",
    });
}

export function fetchAccountNotifyList() {
    return request({
        url: "/backend/account/notify/sms/mobile",
        method: "get",
    });
}

export function createAccountNotify(Mobile, Name) {
    return request({
        url: "/backend/account/notify/sms/mobile",
        method: "post",
        data: {
            Mobile: Mobile,
            Name: Name,
        },
    });
}
export function delAccountNotify(id) {
    return request({
        url: "/backend/account/notify/sms/mobile/" + id,
        method: "delete",
    });
}

// - [x] 第三方账户 /v1/backend/account/thirdparty
export function getThirdparty() {
    return request({
        url: "/backend/account/thirdparty",
        method: "get",
    });
}

// - [x] 推荐用户  /v1/backend/account/invite/info
export function getInviteInfo() {
    return request({
        url: "/backend/account/invite/info",
        method: "get",
    });
}
// 注册的时候，传入这个参数 invite_code
export function getInviteCode() {
    return request({
        url: "/backend/account/invite/code",
        method: "get",
    });
}

// - 第三方账号绑定接口 3秒请求一次
export function checkBind(EventKey) {
    return request({
        url: "/front/user/check_bind",
        method: "get",
        params: {
            EventKey,
        },
    });
}

// - 第三方账号解除绑定接口
export function unBind() {
    return request({
        url: "/backend/account/thirdparty-empty",
        method: "get",
    });
}