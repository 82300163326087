<template>
  <div class="footer">
    <div class="footer-left pull-left">
      <a href="/">
        <img src="@/assets/img/logo_app.png" alt="元素（深圳）软件有限公司 "></a>
    </div>
    <!-- <div class="footer-right pull-right">
      <a :href="$store.getters.docUrl">文档</a>
      <a :href="$store.getters.docUrl + '/video/index.html'" target="_blank">视频教程</a>
      <router-link to="/downloads">下载</router-link>
      <router-link to="/prices">价格</router-link>
      <a :href="$store.getters.docUrl + '/other/common_problem'">FAQ</a>
    </div> -->
    <div v-if="showBox" class="box-fixed">
      <el-button class="btn-close" icon="el-icon-close" size="mini" circle @click="closeBox" />
      <el-popover v-if="show11" placement="left" trigger="hover" width="170">
        <img width="150px" src="@/assets/images/wxcode.png">
        <div class="t-center">企业专属</div>
        <div slot="reference" class="v-contact">
          <img class="img0" src="@/assets/images/index/i-11.png" alt="企业专属">
          <span>企业专属</span>
        </div>
      </el-popover>
      <el-popover placement="left" trigger="hover" width="170">
        <!-- <img width="150px" src="@/assets/images/qyCode.png"> -->
        <img width="150px" src="@/assets/images/wxcode.png">
        <div class="t-center">微信咨询</div>
        <div slot="reference" class="v-contact">
          <img class="img0" src="@/assets/images/index/i-wx.png" alt="微信接待">
          <span>微信咨询</span>
          <span class="t-time">9:00-18:00</span>
        </div>
      </el-popover>
      <a class="v-contact" target="_blank" href="tencent://message/?uin=2395453054&amp;Site=Sambow&amp;Menu=yes">
        <img class="img0" src="@/assets/images/index/i-qq.png" alt="QQ接待">
        <span>QQ咨询</span>
        <span class="t-time">9:00-18:00</span>
      </a>
      <el-popover placement="left-end" trigger="hover" width="170">
        <img width="150px" src="@/assets/images/footer-QR.png">
        <div class="t-center">官网微信公众号</div>
        <div slot="reference" class="v-contact v-contact-nb ">
          <img class="img0" src="@/assets/images/index/i-scan.png" alt="扫码关注">
          <span>扫码关注</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { getAccountInfo } from '@/api/account'
export default {
  data() {
    return {
      show11: false,
      showBox: true
    }
  },
  mounted() {
    this.showBox = sessionStorage.getItem('hideBox') != 1
    getAccountInfo().then(res => {
      res = res.data
      this.show11 = (res && res['creditNum'] && res['financeVerify'] == 1) && res.devType == 1
    })
    $('#butterbar').fadeOut()
  },
  methods: {
    closeBox() {
      sessionStorage.setItem('hideBox', 1)
      this.showBox = false
    }
  }
}
</script>
