import request from '@/utils/request'
import store from '@/store'

export function fetchMqttList(appId, params) {
  return request({
    url: '/backend/mqtt_servers/',
    method: 'get',
    params
  })
}
 

export function createMqttServers( data) {
  console.log(  data);
  store.dispatch('data/resetCloudList')
  return request({
    url: `/backend/mqtt_servers/`,
    method: 'post',
    data
   
  })
}

export function getMqttServers(id) {
  return request({
    url: '/backend/mqtt_servers/' + id,
    method: 'get'
  })
}


// 新函数开始----------

// 获取在线设备信息
export function getOnlineDevices(id,params) {
  return request({
    url: '/backend/mqtt_clients/online',
    method: 'get',
    params:params,
  })
}

// 获取订阅列表
export function getClientSubscriptions(id,params) {
  return request({
    url: '/backend/mqtt_clients/subscriptions',
    method: 'get',
    params:params,
  })
}