/*
 * @Author: magic
 * @Date: 2021-09-27 11:57:49
 * @LastEditors: magic
 * @LastEditTime: 2022-04-19 11:26:51
 * @Description: Do not edit
 * @FilePath: /www.bmob.cn/src/utils/auth.js
 */
import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

export function getToken() {
  return Cookies.get(TokenKey) && localStorage.getItem("userInfo");
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
