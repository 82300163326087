import { login, autoLogin, logout } from '@/api/user'
import { getToken, removeToken } from '@/utils/auth'
import { setUserInfo, getUserInfo } from '@/api/user'

const state = {
  token: getToken(),
  username: '',
  userId: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  }
}

const actions = {
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.id)
          setUserInfo(data)
          resolve()
        })
        .catch((error) => {
          console.error('login', error)
          reject(error)
        })
    })
  },

  autoLogin({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      autoLogin({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.id)
          setUserInfo(data)
          resolve()
        })
        .catch((error) => {
          console.error('login', error)
          reject(error)
        })
    })
  },

  // loginByCode({ commit }, code) {
  //   return new Promise((resolve, reject) => {
  //     loginByCode(code).then(response => {
  //       const { data } = response
  //       commit('SET_TOKEN', data.id)
  //       setUserInfo(data)
  //       resolve()
  //     }).catch(error => {
  //       console.error('loginByCode', error)
  //       reject(error)
  //     })
  //   })
  // },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      const userInfo = getUserInfo()
      commit('SET_USERNAME', userInfo.mail)
      commit('SET_USERID', userInfo.id)
      console.log('getInfo', userInfo)
      resolve(userInfo)
    })
  },

  // user logout
  logout({ commit }) {
    function clearAllCookie() {
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
      if (keys) {
        console.log('clearAllCookie', keys)
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
        }
      }
    }
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '')
          localStorage.removeItem('userInfo')
          removeToken()
          clearAllCookie()
          resolve()
        })
        .catch((error) => {
          commit('SET_TOKEN', '')
          localStorage.removeItem('userInfo')
          removeToken()
          clearAllCookie()
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      localStorage.removeItem('userInfo')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
