import router from './router'
// import store from './store'
// import { MessageBox } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import { getUserInfo } from "@/api/user";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  '/',
  '/404',
  '/401',
  '/login',
  '/register',
  '/cloud',
  '/products',
  '/ai',
  '/shop/index',
  '/downloads',
  '/prices',
  '/function',
  '/hook',
  '/iot',
  '/harmoney',
  '/weixin',
  '/sms',
  '/about',
  '/service',
  '/privacy',
  '/partners',
  '/friendlink',
  '/school'
] // no redirect whitelist

// const bindList = ['/workOrder/index', '/account/info']

router.beforeEach(async(to, from, next) => {
  // console.log('to', to.path)

  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // 已登陆
    if (to.path === '/login') {
      if (undefined !== to.query.d) {
        next({ path: to.query.d })
      } else {
        next({ path: '/' })
      }
    } else if (to.path === '/register') {
      next({ path: '/app/list' })
    }
    // else if (bindList.includes(to.path) && !getUserInfo().openId) {
    //   MessageBox.confirm(
    //     "尊敬的Bmob用户，为了更好的提高工单的处理效率，请先绑定Bmob后端云官方微信公众号（微信号：bmober），绑定后可以随时了解工单进度，及时解决问题。",
    //     "提示",
    //     {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       next({ path: "/account/thirdparty" });
    //     })
    //     .catch(() => {});
    // }
    else {
      window.APPID = to.params.appId || ''
      next()
    }
    NProgress.done()
    // else {
    //   // determine whether the user has obtained his permission roles through getInfo
    //   const hasRoles = store.getters.roles && store.getters.roles.length > 0
    //   if (hasRoles) {
    //     next()
    //   } else {
    //     try {
    //       // get user info
    //       // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
    //       const { roles } = await store.dispatch('user/getInfo')
    //       // console.log('roles >', roles)

    //       // generate accessible routes map based on roles
    //       const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

    //       // dynamically add accessible routes
    //       router.addRoutes(accessRoutes)

    //       // hack method to ensure that addRoutes is complete
    //       // set the replace: true, so the navigation will not leave a history record
    //       next({ ...to, replace: true })
    //     } catch (error) {
    //       // remove token and go to login page to re-login
    //       await store.dispatch('user/resetToken')
    //       Message.error(error || 'Has Error')
    //       next(`/login?redirect=${to.path}`)
    //       NProgress.done()
    //     }
    //   }
    // }
  } else {
    /* has no token*/
    if (
      whiteList.indexOf(to.path) !== -1 ||
      to.path.includes('/site') ||
      to.path.includes('/shop/index') ||
      to.path.includes('/shop/detail') ||
      to.path.includes('/repository')
    ) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // to, from
  // finish progress bar
  NProgress.done()
})
