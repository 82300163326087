/*
 * @Author: your name
 * @Date: 2020-05-06 14:07:52
 * @LastEditTime: 2020-05-29 16:17:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wechatgj-admin/src/utils/request.js
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  withCredentials: false, //
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    // }

    var url = config.url
    // console.log('url', url, config)
    if (window.APPID) {
      config.params = config.params || {}
      if (url.indexOf('/bai') > -1) {
        if (url === '/bai/app/' && config.method === 'get') ;
        else {
          config.params.app_id = window.APPID
        }
      } else {
        config.params.appId = window.APPID
      }
    }
    if (config.method === 'get' && config.params) {
      url += '?'
      const keys = Object.keys(config.params)
      for (const key of keys) {
        if (key === 'query') {
          var a = config.params[key].split(',')
          if (a.length) {
            url += 'query='
            a.forEach((e, index) => {
              if (e.indexOf(':') === -1) {
                if (index - 1 >= 0) {
                  let i = 1
                  let str = (',' + e)
                  while (str) {
                    if (a[index - i]) {
                      a[index - i] += str
                      a[index] = null
                      str = null
                    } else {
                      i++
                    }
                  }
                }
              }
            })
            // console.log('a', a)
            if (a.length) {
              a.forEach(k => {
                if (k) {
                  k = k.split(':')
                  url += k[0] + ':' + encodeURIComponent(k[1]) + ','
                }
              })
              url = url.substring(0, url.length - 1)
            }
            url += '&'
          }
        } else {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
    }
    config.url = url
    return config
  },
  error => {
    // do something with request error
    console.error(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  response => {
    const res = response.data
    if (!res) {
      Message({
        message: 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error('Error'))
    }

    if (res.cdn) {
      // console.log('上传文件', res.cdn)
      return res
    }
    if (response.config.url.indexOf('/chat/completions/getChatQuantity') > -1) {
      return response
    }

    if (response.config.url.indexOf('/backend/table/data') > -1) {
      return res
    }

    if (response.config.url.indexOf('?for_check_login=1') > -1) {
      return res
    }

    // if the custom code is not 200, it is judged as an error.
    if ((res.code !== 200 && !res.CreatedAt && !res.Id) || res.error) {
      if (res.msg == '用户未登录' || res.code == 503) {
        const now = (new Date()).getTime()
        if (window.showParseTime && (window.showParseTime + 1000) > now) {
          console.log('1s')
          return
        }
        Message({
          message: '登录状态过期啦，正在跳转登录',
          type: 'error',
          duration: 5 * 1000
        })
        window.showParseTime = now
        // MessageBox.confirm(res.error, '登录状态过期啦', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '回到首页',
        //     type: 'warning'
        // }).then(() => {
        store.dispatch('user/logout').then(() => {
          window.location.href = '/login'
        }).catch(() => {
          window.location.href = '/login'
        //   location.reload()
        })
        // }).catch(() => {
        //     store.dispatch('user/logout').then(() => {
        //         window.location.href = '/'
        //     }).catch(() => {
        //         window.location.href = '/'
        //     })
        // })
      } else {
        var message = res.msg || res.message || 'Error'
        if (message.includes('duplicate')) {
          message = ' 您的数据列存在重复值，请删除重复后设置。'
        }

        if (response.config.url.indexOf('/front/user/login') > -1 && message === 'user not exist') { message = '账号密码错误' }

        if (response.config.url.indexOf('/manualBackup') > -1 && message === '已经超套餐限制')
          ;
        else {
          Message({
            message,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
      return Promise.reject(new Error(res.msg || res.message || 'Error'))
    } else {
      if (res.data) {
        if (typeof res.data === 'string') return res
        if (res.data.results) {
          if (Array.isArray(res.data.results)) {
            res.data.results = res.data.results.map(e => {
              var res = {}
              Object.keys(e).forEach(k => {
                res[k.substring(0, 1).toLowerCase() + k.substring(1)] = e[k]
              })
              return res
            })
          }
        } else if (Array.isArray(res.data)) {
          if (res.data.length && typeof res.data[0] != 'object') {
            return res
          }
          res.data = res.data.map(e => {
            var res = {}
            Object.keys(e).forEach(k => {
              res[k.substring(0, 1).toLowerCase() + k.substring(1)] = e[k]
            })
            return res
          })
        } else {
          var row = {}
          Object.keys(res.data).forEach(k => {
            row[k.substring(0, 1).toLowerCase() + k.substring(1)] = res.data[k]
          })
          res.data = row
        }
      } else {
        res.data = []
      }
      return res
    }
  },
  error => {
    const now = (new Date()).getTime()
    if (window.showParseTime && (window.showParseTime + 1000) > now) {
      console.log('1s')
      return
    }
    console.log('request errerrerrerrerrerr', error) // for debug
    console.log(JSON.stringify(error)) // for debug
    if (error.code == 503 || error.response.code == 503) { //  || error.message == 'Request failed with status code 503'
      Message({
        message: '登录状态过期啦，正在跳转登录',
        type: 'error',
        duration: 5 * 1000
      })
      window.showParseTime = now
      store.dispatch('user/logout').then(() => {
        location.reload()
      }).catch(() => {
        location.reload()
      })
    } else {
      var message = error.response && error.response.data && error.response.data.error ? error.response.data.error : error.message
      Message({
        message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
