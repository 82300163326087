/*
 * @Author: your name
 * @Date: 2019-10-25 16:42:33
 * @LastEditTime: 2020-06-04 09:54:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wechatgj-admin/src/utils/get-page-title.js
 */
const title = 'Bmob后端云'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
