import request from '@/utils/request'
import store from '@/store'

export function getApp(id) {
  return request({
    url: `/bai/app/` + id,
    method: 'get'
  })
}
export function getAppList(params) {
  return request({
    url: `/bai/app/`,
    method: 'get',
    params
  })
}
export function createApp(name) {
  store.dispatch('data/resetAIAppList')
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/app/`,
    method: 'post',
    data: { name }
  })
}
export function delApp(appId) {
  store.dispatch('data/resetAIAppList')
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/app/${appId}`,
    method: 'delete'
  })
}
export function updateApp(appId, name) {
  store.dispatch('data/resetAIAppList')
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/app/${appId}`,
    method: 'put',
    data: {
      name
    }
  })
}

//
export function getClass(id) {
  return request({
    url: `/bai/class/` + id,
    method: 'get'
  })
}

export function getRole(id) {
  return request({
    url: `/dev/role/` + id,
    method: 'get'
  })
}
export function getClassList(params) {
  return request({
    url: `/bai/class`,
    method: 'get',
    params
  })
}
export function postClass(data) {
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/class`,
    method: 'post',
    data
  })
}
export function delClass(classId) {
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/class/${classId}`,
    method: 'delete'
  })
}
export function updateClass(classId, data) {
  store.dispatch('data/resetAIClassList')
  return request({
    url: `/bai/class/${classId}`,
    method: 'put',
    data
  })
}

// 未命中（就是AI提问文档搜索不到的结果展示给他看）
export function getClassAnswer(params) {
  return request({
    url: `/bai/class_answer/`,
    method: 'get',
    params
  })
}
//
export function getFileList(params) {
  return request({
    url: `/bai/file/`,
    method: 'get',
    params
  })
}
export function getFileInfo(params) {
  return request({
    url: `/bai/file/info`,
    method: 'get',
    params
  })
}
export function addFileInfo(data) {
  return request({
    url: `/bai/file/info`,
    method: 'post',
    data
  })
}
export function delFileInfo(id) {
  return request({
    url: `/bai/file/info/${id}`,
    method: 'delete'
  })
}
export function delFile(id) {
  return request({
    url: `/bai/file/${id}`,
    method: 'delete'
  })
}

// create Ai文件上传
// export function uploadFile(app_id, files) {
//   return request({
//     url: `/bai/file/upload?app_id=${app_id}`,
//     method: 'post',
//     data: { files }
//   })
// }

// 文件上传后，通过这个接口预览文本分隔
// app_id
// cid
// file_id
// process_rule
export function previewFile(data) {
  return request({
    url: `/bai/file/preview`,
    method: 'post',
    data
  })
}

export function saveFile(data) {
  return request({
    url: `/bai/file/save`,
    method: 'post',
    data
  })
}

//

export function getStat(params) {
  return request({
    url: `/bai/stat/analysis`,
    method: 'get',
    params
  })
}
