import request from '@/utils/request'
import store from '@/store'

import Vue from 'vue'
const vm = new Vue()

export function fetchAppList() {
  return request({
    url: '/backend/app',
    method: 'get',
    params: {
      query: 'Own:' + vm.$getUserId(),
      limit: 100,
      sortby: 'Created',
      order: 'desc'
    }
  })
}

export function createApp(data) {
  store.dispatch('data/resetAppList')
  return request({
    url: '/backend/app/create',
    method: 'post',
    data
  })
}

export function fetchAppBanner() {
  return request({
    url: '/front/banner',
    method: 'get'
  })
}

export function getApp(id) {
  return request({
    url: '/backend/app/' + id,
    method: 'get'
  })
}

export function updateApp(id, data) {
  store.dispatch('data/resetAppList')
  return request({
    url: '/backend/app/' + id,
    method: 'put',
    data
  })
}

export function unbindApp(id, Password) {
  store.dispatch('data/resetAppList')
  return request({
    url: `/backend/app/${id}/unbind`,
    method: 'put',
    data: {
      Password
    }
  })
}

export function delApp(id, Password) {
  store.dispatch('data/resetAppList')
  return request({
    url: '/backend/app/' + id,
    method: 'delete',
    data: {
      Password
    }
  })
}

// 获取应用配置信息
export function getCloudHook(id) {
  return request({
    url: `/backend/cloud_hook`,
    method: 'get'
  })
}

export function postCloudHook(id, data) {
  return request({
    url: `/backend/cloud_hook`,
    method: 'post',
    data
  })
}

// 获取应用配置信息
export function getAppConfig(id) {
  return request({
    url: `/backend/app/${id}/app_config`,
    method: 'get'
  })
}

// 更新Android/iOS App微信帐号服务配置
export function updateAppConfig(id, data) {
  return request({
    url: `/backend/app/${id}/app_config/android_ios_wechat`,
    method: 'put',
    data
  })
}

// 更新QQ小程序帐号服务配置
export function updateAppConfigQQ(id, data) {
  return request({
    url: `/backend/app/${id}/app_config/qq`,
    method: 'put',
    data
  })
}

// 更新头条小程序帐号服务配置
export function updateAppConfigToutiao(id, data) {
  return request({
    url: `/backend/app/${id}/app_config/toutiao`,
    method: 'put',
    data
  })
}

// 更新微信小程序帐号服务配置
export function updateAppConfigWechat(id, data) {
  return request({
    url: `/backend/app/${id}/app_config/wechat`,
    method: 'put',
    data
  })
}
// 获取应用绑定的域名信息
export function getAppDomain(id) {
  return request({
    url: `/backend/app/${id}/domain`,
    method: 'get'
  })
}

// 获取验证信息
export function getDomainVerify(id, params) {
  return request({
    url: `/backend/app/${id}/domain-verify`,
    method: 'get',
    params
  })
}
// 验证
export function domainVerify(id, params) {
  return request({
    url: `/backend/app/${id}/domain-verify-ok`,
    method: 'get',
    params
  })
}

// 添加绑定域名
export function createAppDomain(id, data) {
  return request({
    url: `/backend/app/${id}/domain`,
    method: 'post',
    data
  })
}

// 删除绑定域名
export function delAppDomain(id) {
  return request({
    url: `/backend/app/${id}/domain`,
    method: 'delete'
  })
}

// 更新API安全码
export function updateApiSecurityCode(id, data) {
  return request({
    url: `/backend/app/${id}/security/api_security_code`,
    method: 'put',
    data
  })
}

// 更新数据安全、安全认证、Android APK发布的签名、iOS IPA的Bundle ID、调试IP白名单
export function updateAppSecurity(id, data) {
  return request({
    url: `/backend/app/${id}/security/app`,
    method: 'put',
    data
  })
}

// 获取数据安全、安全认证、Android APK发布的签名、iOS IPA的Bundle ID、调试IP白名单
export function getAppSecurity(id) {
  return request({
    url: `/backend/app/${id}/security/app`,
    method: 'get'
  })
}

// 获取应用基础信息
export function getAppBasic(id) {
  return request({
    url: `/backend/app/${id}/settings/basic`,
    method: 'get'
  })
}

// 更新应用基础信息
export function updateAppBasic(id, data) {
  return request({
    url: `/backend/app/${id}/settings/basic`,
    method: 'put',
    data
  })
}

// 更新文件链接是否返回https属性
export function updateAppFileHttps(id, data) {
  return request({
    url: `/backend/app/${id}/settings/basic/https_file_url_open`,
    method: 'put',
    data
  })
}

// 是否开启手机号加密
export function updateTelEncryption(id, data) {
  return request({
    url: `/backend/app/${id}/settings/basic/tel_encryption`,
    method: 'put',
    data
  })
}

// 更新应用URL地址
// export function updateAppLink(id, data) {
//   return request({
//     url: `/backend/app/${id}/settings/basic/link`,
//     method: 'put',
//     data
//   })
// }

// 更新允许SDK建表属性
export function updateAppSDK(id, data) {
  return request({
    url: `/backend/app/${id}/settings/basic/no_create_table`,
    method: 'put',
    data
  })
}

// 获取应用邮件设置信息
export function getAppEmail(id) {
  return request({
    url: `/backend/app/${id}/settings/email`,
    method: 'get'
  })
}

// 更新邮件发送设置
export function updateAppEmailSwitch(id, data) {
  return request({
    url: `/backend/app/${id}/settings/email/switch`,
    method: 'put',
    data
  })
}

// 更新邮件模板
export function updateEmailTemplate(id, data) {
  return request({
    url: `/backend/app/${id}/settings/email/template`,
    method: 'put',
    data
  })
}

// 获取应用密钥
export function getAppKeys(id) {
  return request({
    url: `/backend/app/${id}/settings/keys`,
    method: 'get'
  })
}

// 重置应用密钥(RestKey和MasterKey)
export function updateAppKeys(id, keyType) {
  return request({
    url: `/backend/app/${id}/settings/keys`,
    method: 'put',
    params: {
      keyType //  restKey 或 masterKey
    },
    data: {
      keyType //  restKey 或 masterKey
    }
  })
}

// 素材列表
export function fetchFileList(appId, params) {
  return request({
    url: `/backend/table/data/${appId}/file_upload_log`,
    method: 'get',
    params
  })
}
// 删除素材
export function delFile(idArr) {
  const s = idArr.reduce((p, c) => {
    return p + 'oid[]=' + c + '&'
  }, '')
  return request({
    url: `/backend/table/data/upload/delete?${s.substring(0, s.length - 1)}`,
    method: 'delete'
  })
}

// 获取导入选择字段
export function getImport(tid) {
  return request({
    url: `/backend/table/import/${tid}`,
    method: 'get'
  })
}

// 获取备份记录
export function getAppBackup(id) {
  return request({
    url: `/backend/app/${id}/backup`,
    method: 'get'
  })
}

// 导入选择字段
export function createImport(tid, tName, data) {
  return request({
    url: `/backend/table/import/${tid}?tName=${tName}`,
    method: 'post',
    data
  })
}

// 获取还原记录
export function getAppRestore(id) {
  return request({
    url: `/backend/app/${id}/restore`,
    method: 'get'
  })
}

// 手动操作备份
export function manualBackup(backupid) {
  return request({
    url: `/backend/app/${backupid}/manualBackup`,
    method: 'get'
  })
}

// 手动操作恢复
export function manualRestore(backupid, appId) {
  return request({
    url: `/backend/app/${appId}/manualRestore`,
    method: 'get',
    params: {
      backupid,
      appId
    }
  })
}

// 备份创建新应用
export function createNewApp(appId, backupid, name, cId) {
  return request({
    url: `/backend/app/${appId}/createnewapp`,
    method: 'get',
    params: {
      backupid,
      name,
      cId
    }
  })
}
