import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'
import Backend from '@/layout/backend'
import App from '@/layout/app'
import Empty from '@/layout/empty'
import Login from '@/layout/login'

export const constantRoutes = [{
  path: '/login',
  meta: {
    title: '用户登录'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/login/index'),
    name: 'index'
  }]
}, {
  path: '/site/adminAuthLogin',
  meta: {
    title: '用户登录'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/login/index'),
    name: 'adminAuthLogin'
  }]
},
{
  path: '/register',
  meta: {
    title: '用户注册'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/login/register'),
    name: 'index'
  }]
},
{
  path: '/site/ForgetPassword',
  meta: {
    title: '找回密码'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/site/forgetpass.vue'),
    name: 'forgetpass'
  }]
},
{
  path: '/site/findpwdSuccess',
  meta: {
    title: '找回密码'
  },
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/findpwdSuccess.vue'),
    name: 'findpwdSuccess'
  }]
},
{
  path: '/site/reset',
  meta: {
    title: '重置密码'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/site/reset.vue'),
    name: 'reset'
  }]
},
{
  path: '/site/result',
  meta: {
    title: '操作结果'
  },
  component: () => import('@/views/site/result.vue')
  // children: [{
  //   path: '',
  //   component: () => import('@/views/site/result.vue'),
  //   name: 'result'
  // }]
},
{
  path: '/site/active',
  meta: {
    title: '账号激活'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/site/active'),
    name: 'index'
  }]
},
{
  path: '/site/outsideReg/1',
  meta: {
    title: '用户注册'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/site/outsidebind'),
    name: 'index'
  }]
},
{
  path: '/site/outsideReg',
  meta: {
    title: '用户注册'
  },
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/outsidereg'),
    name: 'index'
  }]
},
{
  path: '/site/outsideLogin',
  meta: {
    title: '用户注册'
  },
  component: Login,
  children: [{
    path: '',
    component: () => import('@/views/site/outsidelogin'),
    name: 'index'
  }]
},
{
  path: '/site/requestPasswordReset',
  meta: {
    title: 'Bmob Password Reset'
  },
  component: Empty,
  children: [{
    path: '',
    component: () => import('@/views/site/requestPasswordReset'),
    name: 'index'
  }]
},
{
  path: '/site/requestEmailVerify',
  meta: {
    title: 'Bmob Email Verify'
  },
  component: Empty,
  children: [{
    path: '',
    component: () => import('@/views/site/requestEmailVerify'),
    name: 'index'
  }]
},

{
  path: '/404',
  component: () => import('@/views/error-page/404'),
  meta: {
    title: '404'
  }
},
{
  path: '/401',
  component: () => import('@/views/error-page/401'),
  meta: {
    title: '401'
  }
},
{
  path: '/',
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/index1'),
    name: 'index'
  }]
},
{
  path: '/school',
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/school'),
    name: 'School'
  }]
},
{
  path: '/cloud',
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/cloud'),
    name: 'cloud',
    meta: {
      title: '云数据库（Serverless）'
    }
  },
  {
    path: '/function',
    component: () => import('@/views/site/function'),
    name: 'function',
    meta: {
      title: '云函数（FaaS）'
    }
  },
  {
    path: '/weixin',
    component: () => import('@/views/site/weixin'),
    name: 'weixin',
    meta: {
      title: '产品介绍'
    }
  },
  {
    path: '/sms',
    component: () => import('@/views/site/sms'),
    name: 'sms',
    meta: {
      title: '产品介绍'
    }
  },
  {
    path: '/iot',
    component: () => import('@/views/site/iot'),
    name: 'iot 物联网',
    meta: {
      title: 'iot 物联网'
    }
  },
  {
    path: '/products',
    component: () => import('@/views/site/cloud'),
    name: 'products',
    meta: {
      title: '云函数'
    }
  },
  {
    path: '/ai',
    component: () => import('@/views/site/ai'),
    name: 'ai',
    meta: {
      title: 'Bmob-AI'
    }
  },
  {
    path: '/hook',
    component: () => import('@/views/site/hook'),
    name: 'hook',
    meta: {
      title: 'Bmob-钩子'
    }
  },
  {
    path: '/harmoney',
    component: () => import('@/views/site/harmoney'),
    name: 'harmoney',
    meta: {
      title: 'Bmob-鸿蒙SDK'
    }
  },
  //
  {
    path: '/about',
    component: () => import('@/views/site/about'),
    name: 'about',
    meta: {
      title: '公司简介'
    }
  },
  {
    path: '/service',
    component: () => import('@/views/site/service'),
    name: 'service',
    meta: {
      title: '服务条款'
    }
  },
  {
    path: '/privacy',
    component: () => import('@/views/site/privacy'),
    name: 'privacy',
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/friendlink',
    component: () => import('@/views/site/friendlink'),
    name: 'friendlink',
    meta: {
      title: '友情链接'
    }
  },
  {
    path: '/partners',
    component: () => import('@/views/site/partners'),
    name: 'partners',
    meta: {
      title: '合作伙伴'
    }
  },
  {
    path: '/site/news',
    component: () => import('@/views/site/news/index'),
    name: 'news',
    meta: {
      title: '新闻动态'
    }
  },
  {
    path: '/site/news/:id',
    component: () => import('@/views/site/news/detail'),
    name: 'newsDetail',
    meta: {
      title: '新闻详情'
    }
  }
  ]
},
{
  path: '/shop',
  component: Layout,
  children: [{
    path: '/shop/index',
    component: () => import('@/views/shop/index'),
    name: 'shop',
    meta: {
      title: '源码交易市场'
    }
  },
  {
    path: '/shop/detail/:id',
    component: () => import('@/views/shop/detail'),
    name: 'shop',
    meta: {
      title: '项目详情'
    }
  },
  {
    path: '/shop/paySuccess',
    component: () => import('@/views/shop/paySuccess'),
    name: 'shop',
    meta: {
      title: '项目购买成功'
    }
  }
  ]
},
{
  path: '/prices',
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/prices'),
    name: 'prices',
    meta: {
      title: '价格'
    }
  }]
},
{
  path: '/downloads',
  component: Layout,
  children: [{
    path: '',
    component: () => import('@/views/site/downloads'),
    name: 'downloads',
    meta: {
      title: '下载'
    }
  }]
},
{
  path: '/repository',
  component: Layout,
  children: [{
    path: '/repository/index',
    component: () => import('@/views/repository/index'),
    meta: {
      title: '知识库'
    }
  },
  {
    path: '/repository/detail/:id',
    component: () => import('@/views/repository/detail'),
    meta: {
      title: '知识库'
    }
  },
  {
    path: '/repository/search',
    component: () => import('@/views/repository/search'),
    meta: {
      title: '知识库'
    }
  }
  ]
}
]

const backendRoutes = [
  {
    path: '/helper',
    component: Backend,
    children: [{
      path: '/helper/index',
      component: () => import('@/views/helper/index'),
      name: 'helper',
      meta: {
        title: '编程助手'
      }
    }
    ]
  },
  {
    path: '/ai',
    component: Backend,
    children: [
      {
        path: '/ai/list',
        component: () => import('@/views/ai/list'),
        name: 'ai',
        meta: {
          title: '编程助手'
        }
      },
      {
        path: '/ai/detail/:appId',
        component: () => import('@/views/ai/index'),
        name: 'ai',
        meta: {
          title: '编程助手'
        }
      },
      {
        path: '/ai/detail/:appId',
        component: () => import('@/views/ai/index'),
        name: 'ai',
        meta: {
          title: '编程助手'
        }
      },
      {
        path: '/ai/detail/:appId/:cid',
        component: () => import('@/views/ai/detail'),
        name: 'ai',
        meta: {
          title: '编程助手'
        }
      },
      {
        path: '/ai/detail/:appId/:cid/:fid',
        component: () => import('@/views/ai/info'),
        name: 'ai',
        meta: {
          title: '编程助手'
        }
      }
    ]
  },
  {
    path: '/app',
    component: Backend, // () => import('@/layout/app')
    children: [{
      path: '/app/list',
      component: () => import('@/views/app/list'),
      name: 'appList',
      meta: {
        title: '应用列表'
      }
    }]
  },
  {
    path: '/shop',
    component: Backend, // () => import('@/layout/app')
    children: [{
      path: '/shop/getProject',
      component: () => import('@/views/shop/getProject'),
      meta: {
        title: '发布项目'
      }
    },
    {
      path: '/shop/publish',
      component: () => import('@/views/shop/publish'),
      meta: {
        title: '发布项目'
      }
    },
    {
      path: '/shop/publishSuccess',
      component: () => import('@/views/shop/publishSuccess'),
      meta: {
        title: '发布项目'
      }
    },
    {
      path: '/shop/det/:id',
      component: () => import('@/views/shop/det'),
      meta: {
        title: '项目交易详情'
      }
    },
    {
      path: '/shop/Bdetail/:id',
      component: () => import('@/views/shop/det'),
      meta: {
        title: '项目交易详情'
      }
    },
    {
      path: '/shop/edit/:id',
      component: () => import('@/views/shop/edit'),
      meta: {
        title: '编辑项目'
      }
    },
    {
      path: '/shop/getBuyProject',
      component: () => import('@/views/shop/buyProject'),
      meta: {
        title: '购买项目'
      }
    },
    {
      path: '/shop/allIncome',
      component: () => import('@/views/shop/allIncome'),
      meta: {
        title: '交易明细'
      }
    }
    ]
  },
  {
    path: '/increment',
    component: Backend,
    children: [{
      path: '/increment/devlist',
      component: () => import('@/views/increment/devlist'),
      name: 'incrementDevlist',
      meta: {
        title: '定制开发'
      }
    },
    {
      path: '/increment/devpublish',
      component: () => import('@/views/increment/devpublish'),
      meta: {
        title: '发布需求'
      }
    }
    ]
  },
  {
    path: '/finance',
    component: Backend,
    children: [{
      path: '/finance/index',
      component: () => import('@/views/finance/index.vue'),
      name: 'financeIndex',
      meta: {
        title: '账务管理'
      }
    },
    {
      path: '/finance/order',
      component: () => import('@/views/finance/order'),
      name: 'financeOrder',
      meta: {
        title: '交易明细'
      }
    },
    // {
    //   path: '/finance/log',
    //   component: () => import('@/views/finance/log'),
    //   name: 'financeLog',
    //   meta: {
    //     title: '提款管理'
    //   }
    // },
    {
      path: '/finance/invoice',
      component: () => import('@/views/finance/invoice'),
      name: 'financeInvoice',
      meta: {
        title: '发票管理'
      }
    },
    {
      path: '/finance/invoiceApply',
      component: () => import('@/views/finance/invoiceApply'),
      name: 'financeInvoiceApply',
      meta: {
        title: '发票申请'
      }
    },
    {
      path: '/finance/invite',
      component: () => import('@/views/finance/invite'),
      name: 'financeInvite',
      meta: {
        title: '交易明细'
      }
    }
    ]
  },
  {
    path: '/notify/index',
    component: Backend,
    meta: {
      title: '消息管理'
    },
    children: [{
      path: '',
      component: () => import('@/views/notify/list')
    }]
  },
  {
    path: '/notify/redirtToNotify',
    component: () => import('@/views/notify/redirtToNotify'),
    meta: {
      title: '消息管理'
    },
    children: [{
      path: ''
    }]
  },
  {
    path: '/workOrder',
    component: Backend,
    children: [{
      path: '/workOrder/index',
      component: () => import('@/views/workOrder/index'),
      name: 'workOrder',
      meta: {
        title: '工单列表'
      }
    },
    {
      path: '/workOrder/create',
      component: () => import('@/views/workOrder/create'),
      name: 'workOrderCreate',
      meta: {
        title: '创建工单'
      }
    },
    {
      path: '/workOrder/detail/:id',
      component: () => import('@/views/workOrder/detail'),
      name: 'workOrderDetail',
      meta: {
        title: '工单系统'
      }
    }
    ]
  },
  {
    path: '/account',
    component: Backend,
    children: [{
      path: '/account/index',
      component: () => import('@/views/account/index'),
      meta: {
        title: '用户信息'
      }
    },
    {
      path: '/account/team',
      component: () => import('@/views/account/team'),
      meta: {
        title: '团队管理'
      }
    },
    {
      path: '/account/teamAdd',
      component: () => import('@/views/account/teamAdd.vue'),
      meta: {
        title: '团队管理-新增成员'
      }
    },
    {
      path: '/account/info',
      component: () => import('@/views/account/info'),
      meta: {
        title: '实名认证'
      }
    },

    {
      path: '/account/icp',
      component: () => import('@/views/account/icp'),
      meta: {
        title: '备案进度'
      }
    },
    {
      path: '/account/icpAdd',
      component: () => import('@/views/account/icpAdd'),
      meta: {
        title: '新增备案'
      }
    },
    {
      path: '/account/alarmsms',
      component: () => import('@/views/account/alarmsms'),
      meta: {
        title: '通知设置'
      }
    },
    {
      path: '/account/reset',
      component: () => import('@/views/account/reset'),
      meta: {
        title: '密码修改'
      }
    },
    {
      path: '/account/thirdparty',
      component: () => import('@/views/account/thirdparty'),
      meta: {
        title: '第三方账户'
      }
    },
    {
      path: '/account/invite',
      component: () => import('@/views/account/invite'),
      meta: {
        title: '推荐用户'
      }
    }
    ]
  },
  // app
  {
    path: '/app',
    component: App,
    children: [{
      path: '/app/browser/:appId',
      component: () => import('@/views/app/browindex'),
      meta: {
        title: '云数据库'
      }
    },
    {
      path: '/app/browser/:appId/:tid',
      component: () => import('@/views/app/browser'),
      meta: {
        title: '云数据库'
      }
    },
    {
      path: '/app/setcol/:appId/:tId',
      component: () => import('@/views/app/setcol'),
      meta: {
        title: '设置表字段类型'
      }
    },
    {
      path: '/mqtt/index/:appId',
      component: () => import('@/views/mqtt/index'),
      meta: {
        title: 'IOT 概况'
      }
    },
    {
      path: '/mqtt/info/:appId/:id',
      component: () => import('@/views/mqtt/info'),
      meta: {
        title: 'IOT 详细'
      }
    },
    {
      path: '/mqtt/connection/:appId',
      component: () => import('@/views/mqtt/connection'),
      meta: {
        title: 'IOT 链接信息'
      }
    },
    {
      path: '/mqtt/list/:appId',
      component: () => import('@/views/mqtt/list'),
      meta: {
        title: 'IOT 列表'
      }
    },
    {
      path: '/app/cloud/:appId',
      component: () => import('@/views/app/cloudcode'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/app/cloud/:appId/:codeId',
      component: () => import('@/views/app/cloudcode'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/app/addCloudCategory/:appId',
      component: () => import('@/views/app/addCloudCategory'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/app/cloudCategory/:appId',
      component: () => import('@/views/app/cloudCategory'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/app/addCloud/:appId',
      component: () => import('@/views/app/addCloud'),
      meta: {
        title: '云函数'
      }
    },
    // 新云函数
    {
      path: '/func/cloud/:appId',
      component: () => import('@/views/func/cloudcode'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/func/cloud/:appId/:codeId',
      component: () => import('@/views/func/cloudcode'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/func/addCloudCategory/:appId',
      component: () => import('@/views/func/addCloudCategory'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/func/cloudCategory/:appId',
      component: () => import('@/views/func/cloudCategory'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/func/addCloud/:appId',
      component: () => import('@/views/func/addCloud'),
      meta: {
        title: '云函数'
      }
    },
    {
      path: '/func/publish/:appId',
      component: () => import('@/views/func/publish'),
      meta: {
        title: '云函数'
      }
    },
    // 图文信息
    {
      path: '/app/filemaintain/:appId',
      component: () => import('@/views/app/fileMaintain'),
      meta: {
        title: '应用文件'
      }
    },
    {
      path: '/app/material/:appId',
      component: () => import('@/views/app/fileMaintain'),
      meta: {
        title: '图文消息'
      }
    },
    {
      path: '/app/addMaterial/:appId',
      component: () => import('@/views/app/fileMaintain'),
      meta: {
        title: '图文消息'
      }
    },
    {
      path: '/app/editMaterial/:appId',
      component: () => import('@/views/app/fileMaintain'),
      meta: {
        title: '图文消息'
      }
    },
    // 消息推送
    {
      path: '/app/pushmsg/:appId',
      component: () => import('@/views/app/pushmsg'),
      meta: {
        title: '发送消息'
      }
    },
    {
      path: '/app/pushmsganalysis/:appId',
      component: () => import('@/views/app/pushmsganalysis'),
      meta: {
        title: '推送分析'
      }
    },
    {
      path: '/app/pushmsgsetting/:appId',
      component: () => import('@/views/app/pushmsgsetting'),
      meta: {
        title: '推送设置'
      }
    },
    {
      path: '/app/secret/:appId',
      component: () => import('@/views/app/secret'),
      meta: {
        title: '应用密钥'
      }
    },
    {
      path: '/app/domain/:appId',
      component: () => import('@/views/app/domain'),
      meta: {
        title: '域名管理'
      }
    },
    {
      path: '/app/appauth/:appId',
      component: () => import('@/views/app/appAuth'),
      meta: {
        title: '安全验证'
      }
    },
    {
      path: '/app/backuprestore/:appId',
      component: () => import('@/views/app/backupRestore'),
      meta: {
        title: '备份还原'
      }
    },
    {
      path: '/app/config/:appId',
      component: () => import('@/views/app/config'),
      meta: {
        title: '应用配置'
      }
    },
    {
      path: '/app/setemail/:appId',
      component: () => import('@/views/app/setemail'),
      meta: {
        title: '邮件设置'
      }
    },
    // {
    //   path: '/app/audit/:appId',
    //   component: () => import('@/views/app/audit'),
    //   meta: {
    //     title: '审核信息'
    //   }
    // },
    {
      path: '/app/edit/:appId',
      component: () => import('@/views/app/edit'),
      meta: {
        title: '基础设置'
      }
    },
    {
      path: '/app/uploadTxt/:appId',
      component: () => import('@/views/app/upload'),
      meta: {
        title: '应用设置'
      }
    },
    {
      path: '/app/aiset/:appId',
      component: () => import('@/views/helper/set'),
      meta: {
        title: 'AI设置'
      }
    },
    {
      path: '/app/dataHook/:appId',
      component: () => import('@/views/app/dataHook'),
      meta: {
        title: '钩子配置'
      }
    }
    ]
  },
  {
    path: '/appPay',
    component: App,
    children: [
    //   {
    //   path: '/appPay/balance/:appId',
    //   component: () => import('@/views/appPay/balance'),
    //   meta: {
    //     title: '广告收益'
    //   }
    // },
      {
        path: '/appPay/wechatlog/:appId',
        component: () => import('@/views/appPay/wechatlog'),
        meta: {
          title: '小程序收益'
        }
      },
      {
        path: '/appPay/setUrl/:appId',
        component: () => import('@/views/appPay/setUrl.vue'),
        meta: {
          title: '配置信息'
        }
      }
    ]
  },
  {
    path: '/sms',
    component: App,
    children: [{
      path: '/sms/template/:appId',
      component: () => import('@/views/sms/template'),
      meta: {
        title: '短信信息'
      }
    },
    {
      path: '/sms/setTemplate/:appId',
      component: () => import('@/views/sms/setTemplate'),
      meta: {
        title: '设置短信模板'
      }
    },
    {
      path: '/sms/analyze/:appId',
      component: () => import('@/views/sms/analyze'),
      meta: {
        title: '发送分析'
      }
    },
    {
      path: '/sms/log/:appId',
      component: () => import('@/views/sms/log'),
      meta: {
        title: '发送日志'
      }
    }
    ]
  },
  // 统计分析
  {
    path: '/analysis',
    component: App,
    children: [{
      path: '/analysis/ApiAll/:appId',
      component: () => import('@/views/analysis/apiAll.vue'),
      meta: {
        title: 'API统计'
      }
    },
    {
      path: '/analysis/Storage/:appId',
      component: () => import('@/views/analysis/storage'),
      meta: {
        title: '存储统计'
      }
    },
    {
      path: '/analysis/Flow/:appId',
      component: () => import('@/views/analysis/flow'),
      meta: {
        title: '流量统计'
      }
    },
    {
      path: '/analysis/Email/:appId',
      component: () => import('@/views/analysis/email'),
      meta: {
        title: '邮件统计'
      }
    }, {
      path: '/analysis/Api/:appId',
      component: () => import('@/views/analysis/api.vue'),
      meta: {
        title: '实时API'
      }
    }
    ]
  },
  // 套餐升级
  {
    path: '/upgrade',
    component: App,
    children: [{
      path: '/upgrade/index/:appId',
      component: () => import('@/views/upgrade/index'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/applevel/:appId',
      component: () => import('@/views/upgrade/applevel'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/chargeCloud/:appId',
      component: () => import('@/views/upgrade/chargeCloud'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/domain/:appId',
      component: () => import('@/views/upgrade/domain'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/internet/:appId',
      component: () => import('@/views/upgrade/internet'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/ftp/:appId',
      component: () => import('@/views/upgrade/ftp'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/socket/:appId',
      component: () => import('@/views/upgrade/socket'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/api/:appId',
      component: () => import('@/views/upgrade/api'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/custom/:appId',
      component: () => import('@/views/upgrade/custom'),
      meta: {
        title: '套餐升级'
      }
    },
    {
      path: '/upgrade/sms/:appId',
      component: () => import('@/views/upgrade/sms'),
      meta: {
        title: '短信充值'
      }
    },
    {
      path: '/upgrade/buy/:appId',
      component: () => import('@/views/helper/buy'),
      name: 'helperBuy',
      meta: {
        title: 'ChatGpt 额度充值'
      }
    },
    {
      path: '/upgrade/email/:appId',
      component: () => import('@/views/upgrade/email'),
      meta: {
        title: 'Email充值'
      }
    },
    {
      path: '/upgrade/flow/:appId',
      component: () => import('@/views/upgrade/flow'),
      meta: {
        title: '流量购买'
      }
    },
    {
      path: '/upgrade/storage/:appId',
      component: () => import('@/views/upgrade/storage'),
      meta: {
        title: '存储购买'
      }
    }
    ]
  },
  // 支付
  {
    path: '/pay',
    component: Empty,
    children: [{
      path: '/pay/pay',
      component: () => import('@/views/pay/pay'),
      meta: {
        title: '专版服务'
      }
    },
    {
      path: '/pay/shengPay',
      component: () => import('@/views/pay/shengPay'),
      meta: {
        title: '支付'
      }
    },
    {
      path: '/pay/wxPaySms',
      component: () => import('@/views/pay/wxPaySms'),
      meta: {
        title: '微信支付'
      }
    }
    ]
  }
]

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes.concat(backendRoutes)
  })
const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
