
import { fetchAppList } from '@/api/app'
import { fetchTableList } from '@/api/table'
import { fetchCloudList as fetchCloudList_old } from '@/api/cloud'
import { fetchCloudList } from '@/api/func'
import { fetchMqttList } from '@/api/mqtt'
import { getAppList, getClassList } from '@/api/bai'

const state = {
  appList: [],
  tableList: {},
  aiAppList: [],
  aiClassList: [],
  cloudList: {
    '/app': {},
    '/func': {}
  }
}

const mutations = {
  SET_APP_LIST: (state, appList) => {
    state.appList = appList
  },
  SET_AI_APP_LIST: (state, aiAppList) => {
    state.aiAppList = aiAppList
  },
  SET_AI_CLASS_LIST: (state, aiClassList) => {
    state.aiClassList = aiClassList
  },
  SET_TABLE_LIST: (state, { appId, tableList }) => {
    state.tableList[appId] = tableList
  },
  RESET_TABLE_LIST: (state) => {
    state.tableList = {}
  },
  SET_CLOUD_LIST: (state, { appId, codePath, cloudList }) => {
    state.cloudList[codePath][appId] = cloudList
  },
  RESET_CLOUD_LIST: (state) => {
    state.cloudList = {
      '/app': {},
      '/func': {}}
  }
}

// this.$store.state.data.appList

// this.$store.dispatch('data/initAppList').then(() => {
// }).catch(() => {
// })

const actions = {
  resetAppList({ commit }) {
    console.log('-----------resetAppList-----------')
    commit('SET_APP_LIST', [])
  },
  resetAIAppList({ commit }) {
    console.log('-----------resetAIAppList-----------')
    commit('SET_AI_APP_LIST', [])
  },
  resetAIClassList({ commit }) {
    console.log('-----------resetAIClassList-----------')
    commit('SET_AI_CLASS_LIST', [])
  },
  resetTableList({ commit }) {
    console.log('-----------resetTableList-----------')
    commit('RESET_TABLE_LIST')
  },
  resetCloudList({ commit }) {
    console.log('-----------resetCloudList-----------')
    commit('RESET_CLOUD_LIST')
  },
  initAppList({ commit }) {
    return new Promise((resolve, reject) => {
      fetchAppList()
        .then((res) => {
          res = res.data.results || []
          commit('SET_APP_LIST', res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initAIAppList({ commit }) {
    return new Promise((resolve, reject) => {
      getAppList()
        .then((res) => {
          res = res.data.results || []
          commit('SET_AI_APP_LIST', res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initAIClassList({ commit }) {
    return new Promise((resolve, reject) => {
      getClassList()
        .then((res) => {
          res = res.data.results || []
          commit('SET_AI_CLASS_LIST', res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initTableList({ commit }, appId) {
    return new Promise((resolve, reject) => {
      fetchTableList(appId)
        .then((res) => {
          res = res.data || []
          res.sort((a) => {
            return a.tableNames.indexOf('_') === 0 ? -1 : 1
          })
          commit('SET_TABLE_LIST', { appId, tableList: res })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initCloudList({ commit }, { appId, codePath }) {
    console.log('initCloudList', {
      appId, codePath
    })
    return new Promise((resolve, reject) => {
      const foo = codePath === '/func' ? fetchCloudList : fetchCloudList_old
      foo(appId, { fields: 'CloudName,UserComment,Id' })
        .then((res) => {
          res = res.data || []
          commit('SET_CLOUD_LIST', { appId, codePath, cloudList: res })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  initMqtt({ commit }, { appId, codePath }) {
    console.log('initMqtt', {
      appId, codePath
    })
    return new Promise((resolve, reject) => {
      
      fetchMqttList(appId,{})
        .then((res) => {
          res = res.data || []
          commit('SET_MQTT_LIST', { appId, codePath, cloudList: res })
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
