<template>
  <div class="frontendLayout">
    <Header />
    <router-view :key="$route.path" />

    <Footer v-if="!$route.path.includes('repository')" />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'Login',
  components: { Header, Footer }
}
</script>
<style >
/* @import '~@/styles/frontend/global.css';
@import '~@/styles/frontend/base.css'; */
</style>
