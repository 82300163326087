<template>
  <div>
    <div v-show="!isFull" class="main-left" :style="alertList.length ? 'padding-top: 38px;' : ''">
      <div>
        <div class="choose-app">
          <form action="" method="">
            <el-select v-if="tmp_list && tmp_list.length" v-model="appId" @change="onChangeApp">
              <template v-for="(v) in tmp_list">
                <el-option v-if="v.id" :key="v.id" :value="v.id" :label="v.name" />
              </template>
            </el-select>
          </form>
        </div>
        <template v-if="showAppList()">
          <div id="sidebarList">
            <div>
              <p class="biaoti pd1">
                <b>云数据库</b>
                <!-- data-toggle="modal" data-target="#addlist" -->
                <button id="addTableBut" type="button" class="btn btn-link pull-right mt15 nopd"
                  @click="isCreateTableVisible = true">添加表</button>
              </p>
            </div>
            <form class="search-listname pd1" role="form">
              <div class="form-group">
                <div class="search">
                  <input v-model="searchTableKey" type="search" placeholder="表名搜索" class="form-control">
                  <span class="glyphicon glyphicon-search" />
                </div>
              </div>
            </form>
            <div id="table_list" class="container2 data-result">
              <ul id="div_scroll" class="div_scroll" :data-appid="appId">
                <!-- <li>
                  <a :href="'/app/mqtt/' + appId "  
                      :alt="_Clients"
                      :title="_Clients">
                      _Clients
                    </a>
                </li> -->
                <template v-for="row in docList">
                  <li
                    v-show="!searchTableKey || row['tableNames'].toLocaleLowerCase().indexOf(searchTableKey.toLocaleLowerCase()) > -1"
                    :key="row.id" :class="row['id'] == docId ? 'on' : ''"
                    :title="row['desrc'] ? row['desrc'] + '(表注释)' : row['tableNames']">
                    <a v-if="row['id'] == docId" :href="'/app/browser/' + appId + '/' + row['id']" class="pull-left"
                      :alt="row['tableNames']"
                      :title="row['desrc'] ? row['tableNames'] + '(' + row['desrc'] + ')' : row['tableNames']">
                      <i class="name pull-left" :data-tableid="row['id']" :data-table="row['tableNames']"
                        v-text="(['_Installation', '_User', '_Role'].includes(row['tableNames']) || row['tableNames'].length < 20) ? row['tableNames'] : row['tableNames'].substring(0, 17) + '...'" />
                      <span :id="row['id'] == docId ? 'totalrecords' : ''" class="pull-right" />
                    </a>
                    <router-link v-else :to="'/app/browser/' + appId + '/' + row['id']" class="pull-left"
                      :alt="row['tableNames']"
                      :title="row['desrc'] ? row['tableNames'] + '(' + row['desrc'] + ')' : row['tableNames']">
                      <i class="name pull-left" :data-tableid="row['id']" :data-table="row['tableNames']"
                        v-text="(['_Installation', '_User', '_Role'].includes(row['tableNames']) || row['tableNames'].length < 20) ? row['tableNames'] : row['tableNames'].substring(0, 17) + '...'" />
                      <span :id="row['id'] == docId ? 'totalrecords' : ''" class="pull-right" />
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </template>
        <template v-else-if="$route.path.includes('/ai/detail/')">
          <div id="sidebarList">
            <div>
              <p class="biaoti pd1">
                <b>AI助手</b>
                <button type="button" class="btn btn-link pull-right mt15 nopd"
                  @click="isCreateAIVisible = true">添加角色</button>
              </p>
            </div>
            <form class="search-listname pd1" role="form">
              <div class="form-group">
                <div class="search">
                  <input v-model="searchTableKey" type="search" placeholder="角色名搜索" class="form-control">
                  <span class="glyphicon glyphicon-search" />
                </div>
              </div>
            </form>
            <div id="table_list" class="container2 data-result">
              <ul id="div_scroll" class="div_scroll" :data-appid="appId">
                <template v-for="row in docList">
                  <li v-show="!searchTableKey || row['name'].indexOf(searchTableKey) > -1" :key="row.iD"
                    :class="row['iD'] == cid ? 'on' : ''">
                    <a v-if="row['iD'] == cid" :href="'/ai/detail/' + appId + '/' + row['iD']" class="pull-left">
                      <i class="name pull-left"
                        v-text="(row['name'].length < 20) ? row['name'] : row['name'].substring(0, 17) + '...'" />
                      <span :id="row['iD'] == cid ? 'totalrecords' : ''" class="pull-right" />
                    </a>
                    <router-link v-else :to="'/ai/detail/' + appId + '/' + row['iD']" class="pull-left">
                      <i class="name pull-left"
                        v-text="(row['name'].length < 20) ? row['name'] : row['name'].substring(0, 17) + '...'" />
                      <span :id="row['iD'] == cid ? 'totalrecords' : ''" class="pull-right" />
                    </router-link>
                    <span class="remove pull-right " @click="delClass(row)" />
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </template>
        <template
          v-else-if="$route.path.includes('cloud/') || $route.path.includes('cloudCategory/') || $route.path.includes('addCloud')">
          <div>
            <p class="biaoti pd1">
              <b>云函数</b>
              <router-link :to="codePath + '/addCloud/' + appId" class="btn btn-link pull-right mt15 nopd">
                添加</router-link>
              <router-link style="margin-right: 10px;" :to="codePath + '/addCloudCategory/' + appId"
                class="btn btn-link pull-right mt15 nopd">分类</router-link>
            </p>
          </div>
          <form id="search_form" class="search-listname pd1"  onsubmit="return false" method="post" role="form">
            <div class="form-group">
              <div class="search">
                <input id="" v-model="searchTableKey" type="search" name="searchWord" placeholder="方法名搜索"
                  class="form-control">
                <span id="search_btn" class="glyphicon glyphicon-search" />
              </div>
            </div>
          </form>
          <div class="container2 data-result cloud-data-result">
            <ul id="div_scroll" class="div_scroll">
              <li v-for="row in codeList"
                v-show="!searchTableKey || row['cloudName'].toLocaleLowerCase().indexOf(searchTableKey.toLocaleLowerCase()) > -1"
                :key="row.id" :class="row['id'] == codeId ? 'on' : ''">
                <p>
                  <a :id="'a_cloudcode_' + row['cloudName']" :cloud-name="row['cloudName']" :cloud-id="row['id']"
                    :title="row['cloudName'] + (row['userComment'] ? '(' + row['userComment'] + ')' : '')"
                    @click="onClickCloudFun(codePath + `/cloud/${appId}/${row.id}`)">
                    {{ row['cloudName'].length > 80 ? row['cloudName'].substring(0, 80) + '...' : row['cloudName'] }}
                  </a>
                  <span :cloudName="row['cloudName']" :cloudId="row['id']" class="remove  pull-right " role="menuitem"
                    tabindex="-1" @click="delCloud(row.cloudName, row)" />
                </p>
              </li>
            </ul>
          </div>
        </template>

        <template v-else-if="$route.path.includes('mqtt11/') || $route.path.includes('addMqtt11')">
          <div>
            <p class="biaoti pd1">
              <b>IOT</b>

              <!-- <button type="button" class="btn btn-link pull-right mt15 nopd"
                @click="isCreateMqttVisible = true">添加主题</button> -->
                <button type="button" class="btn btn-link pull-right mt15 nopd"
                @click="onClickMqtt(codePath + `/mqtt_list/${appId}`)">设备列表</button>

            </p>
          </div>
          <form id="search_form" class="search-listname pd1" method="post" role="form">
            <div class="form-group">
              <div class="search">
                <input id="" v-model="searchTableKey" type="search" name="searchWord" placeholder="主题搜索"
                  class="form-control">
                <span id="search_btn" class="glyphicon glyphicon-search" />
              </div>
            </div>
          </form>
          <div class="container2 data-result cloud-data-result">
            <ul id="div_scroll" class="div_scroll">
              <li v-for="row in mqttList.results"
                v-show="!searchTableKey || row['cloudName'].toLocaleLowerCase().indexOf(searchTableKey.toLocaleLowerCase()) > -1"
                :key="row.id" :class="row['id'] == codeId ? 'on' : ''">
                <p>
                  <a :id="'a_cloudcode_' + row['name']" :cloud-name="row['name']" :cloud-id="row['id']"
                    :title="row['name'] + (row['topic'] ? '(' + row['topic'] + ')' : '')"
                    @click="onClickMqtt(codePath + `/mqtt/${appId}/${row.id}`)">
                    <!-- {{ row}} -->
                    {{ row['topic'].length > 80 ? row['topic'].substring(0, 80) + '...' : row['topic'] }}
                  </a>
                  <span :cloudName="row['topic']" :cloudId="row['id']" class="remove  pull-right " role="menuitem"
                    tabindex="-1" @click="delCloud(row.cloudName, row)" />
                </p>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="biaoti pd1">
              <b>{{ obj.title }}</b>
            </p>
          </div>
          <ul class="subnav">
            <li v-for="item in obj.arr" :id="item.id || ''" :key="item.href" :class="isOn(item) ? 'on' : ''">
              <router-link :to="item.withoutId ? item.href : (item.href + '/' + appId)">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </template>
      </div>
    </div>
    <!-- 创建表的模态 -->
    <!-- <div id="addlist" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="create-dl" aria-hidden="true"> -->
    <el-drawer title="创建表" custom-class="m-drawer" :visible.sync="isCreateTableVisible">
      <!-- <div class="modal-dialog"> -->
      <!-- <div class="modal-content"> -->
      <!-- <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4 id="create-dl" class="modal-title">创建表</h4>
          </div> -->
      <form class="form-horizontal modal-body app-name-all applist" role="form">
        <div class="form-group">
          <label class="col-sm-2 nopd control-label mt8 col-lg-1 ml15" style="width: 4em" for="app-name">表名称：</label>
          <div class="col-sm-10">
            <el-select id="select_table" v-model="select_table" class="col-sm-3" style="padding-left: 0;">
              <el-option value="system" label="系统表" />
              <el-option value="custom" label="自定义" />
            </el-select>
            <div v-show="select_table == 'system'" id="system_table" class="col-sm-9 col-sm-nopl" style="padding-left:0;">
              <el-select id="system_tables" v-model="system_table" class="col-sm-5" style="padding-left: 0;">
                <el-option value="_Installation" label="_Installation" />
                <el-option value="_Role" label="_Role" />
              </el-select>
            </div>
            <div v-show="select_table == 'custom'" id="custom_table" class="col-sm-9 col-sm-nopl" style="padding:0;">
              <el-form ref="createTableForm" label-width="0" :model="createTableForm">
                <el-form-item label="" prop="input_name" :rules="[{ validator: validatorTableName },]"
                  style="margin: -1px 0 8px">
                  <el-input id="table_name" v-model="createTableForm.input_name" type="text" placeholder="必填"
                    style="margin-bottom:10px;width:100%;" maxlength="16" max-length="16" />
                </el-form-item>
              </el-form>
            </div>
            <p v-if="select_table == 'custom'" class="col-xs-12 nopd mt8">表名称限定长度16个字符，必须以字母开头，允许字符为字母、数字和下划线</p>
          </div>
        </div>
        <div v-show="select_table == 'custom'" id="table_note_div" class="form-group" style="margin-top: 20px">
          <label for="note" class="col-sm-2 nopd control-label mt8 col-lg-1 ml15" style="width: 4em">表注释：</label>
          <div class="col-sm-10">
            <el-input id="table_note" v-model="input_note" type="text" placeholder="选填" />
          </div>
        </div>
        <p id="addTabTip" class="tips" style="display:none" />
      </form>
      <div class="dialog-footer">
        <!-- <button type="button" class="btn btn-default save-btn" data-dismiss="modal">关闭</button> -->
        <!-- <button id="addTableBtn" type="button" class="btn bg-blue save-btn" @click="addTable">创建表</button> -->
        <el-button @click="isCreateTableVisible = false">取消</el-button>
        <el-button type="primary" @click="isCreateTableVisible = false; addTable()">确定</el-button>
      </div>
      <!-- </div>/.modal-content -->
      <!-- </div>/.modal-dialog 添加表的模态窗口 -->
      <!-- </div> 创建表的模态 -->
    </el-drawer>

    <el-drawer title="创建角色" custom-class="m-drawer" :visible.sync="isCreateAIVisible">

      <el-form ref="aiForm" :model="aiForm" :rules="aiRules">
        <el-form-item label="角名称" prop="name">
          <el-input v-model="aiForm.name" />
        </el-form-item>
        <el-form-item label="开场白对话" prop="greeting">
          <p style="color:#999">首次进入对话时，由智能助手发送给对话用户的第一条消息;可以为问候语或功能介绍等。</p>
          <el-input v-model="aiForm.greeting" type="textarea" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="resetForm('aiForm')">取消</el-button>
        <el-button type="primary" @click="submitForm('aiForm')">确定</el-button>
      </div>
      <!-- </div>/.modal-content -->
      <!-- </div>/.modal-dialog 添加表的模态窗口 -->
      <!-- </div> 创建表的模态 -->
    </el-drawer>


    <el-drawer title="创建主题" custom-class="m-drawer" :visible.sync="isCreateMqttVisible">

      <el-form ref="mqttForm" :model="mqttServer" :rules="mqttFormRules">
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="mqttServer.name" />
        </el-form-item>
        <el-form-item label="MQTT主题" prop="topic"> <p style="color:#999"> 16个字符内， 例如：esp8266</p>
          <el-input v-model="mqttServer.topic" />
        </el-form-item>
   
        <!-- <el-form-item v-if="mqttList.count<1" label="客户端ID" prop="cid"> -->
        <el-form-item  label="客户端ID" prop="cid">
          <p style="color:#999">客户端ID与用户名是一样的。</p>
          <el-input v-model="mqttServer.cid" />
        </el-form-item>
  
        <el-form-item   label="MQTT密码" prop="password"> <p style="color:#999">物联网设备链接的密码</p>
          <el-input v-model="mqttServer.password" type="password" />
        </el-form-item>

      </el-form>
      <div class="dialog-footer">
        <el-button @click="resetForm('mqttForm')">取消</el-button>
        <el-button type="primary" @click="submitForm('mqttForm')">确定</el-button>
      </div>

      <!-- </div> 创建主题的模态 -->
    </el-drawer>
  </div>
</template>
<script>
import { createTable } from '@/api/table'
import { postClass, delClass } from '@/api/bai'
import { createMqttServers } from '@/api/mqtt'
export default {
  name: 'AppSidebar',
  props: {
    alertList: Array
  },

  data() {
    return {

      mqttServer: {
        name: '',
        topic: '',
        is_online: false,
        cid: '',
        account: '',
        password: '',

      },
      mqttFormRules: {
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        topic: [{ required: true, message: '请输入MQTT主题', trigger: 'blur' }],

        cid: [{ required: false, message: '请输入客户端ID', trigger: 'blur' }],

        password: [{ required: false, message: '请输入MQTT密码', trigger: 'blur' }],

      },

      appId: Number(this.$route.params.appId),
      isFull: this.$route.path.includes('/browser') && this.$route.query.full,
      docId: this.$route.params.tid,
      cid: this.$route.params.cid,
      codeId: this.$route.params.codeId,
      tmp_list: [],
      searchTableKey: '',
      obj: {},
      routeArr: [
        // <?php elseif($this->action->id=='browser'):?>
        //     <?php echo $this->renderPartial('//app/_appbrowsersidebar'); ?>
        //     <?php elseif($this->id=='browser'):?>
        //     <?php echo $this->renderPartial('//browser/_browsersidebar'); ?>
        {
          arr:['mqtt'],
          obj: {
            title: 'IOT管理',
            arr: [
            {
              name: '概况',
              href: '/mqtt/index'
            },
            
            {
              name: '设备列表',
              href: '/mqtt/list',
              onArr: ['list', 'info']
            },
            {
              name: '连接信息',
              href: '/mqtt/connection'
            }
          ]
          }
        },
        {
          arr: ['analysis'],
          obj: {
            title: '统计分析',
            arr: [{
              name: 'API统计',
              href: '/analysis/ApiAll'
            }, {
              name: '存储统计',
              href: '/analysis/Storage'
            }, {
              name: '流量统计',
              href: '/analysis/Flow'
            }, {
              name: '邮件统计',
              href: '/analysis/Email'
            }, {
              name: '实时API',
              href: '/analysis/Api'
            }]
          }
        },
        {
          arr: ['secret', 'edit', 'appauth', 'backuprestore', 'setemail', 'config', 'appwebsite', 'analysis', 'domain', 'uploadTxt', 'audit', 'upgrade', 'aiset','dataHook'],
          obj: {
            title: '应用设置',
            arr: [{
              name: '应用密钥',
              href: '/app/secret'
            }, {
              name: '套餐升级',
              href: '/upgrade/index',
              onArr: ['*']
            }, {
              name: '域名管理',
              href: '/app/domain'
            }, {
              name: '安全验证',
              href: '/app/appauth'
            }, {
              name: '备份还原',
              href: '/app/backuprestore'
            }, {
              name: '应用配置',
              href: '/app/config'
            }, {
              name: '邮件设置',
              href: '/app/setemail'
            },
            //  {
            //   name: '审核信息',
            //   href: '/app/audit'
            // },
            {
              name: '基础设置',
              href: '/app/edit'
            },
            //  {
            //     name: '团队管理',
            //     href: '/account/team',
            //     withoutId: true
            // }
            {
              name: 'AI设置',
              href: '/app/aiset'
            },
            {
              name: '钩子配置',
              href: '/app/dataHook'
            }
            ]
          }
        },
        {
          arr: ['sms'],
          obj: {
            title: '短信服务',
            arr: [{
              name: '短信信息',
              href: '/sms/template',
              onArr: ['template', 'setTemplate']
            }, {
              name: '发送分析',
              href: '/sms/analyze'
            }, {
              name: '发送日志',
              href: '/sms/log'
            }]
          }
        },
        {
          arr: ['filemaintain', 'material', 'addMaterial', 'editMaterial'],
          obj: {
            title: '素材管理',
            arr: [{
              name: '应用文件',
              href: '/app/filemaintain'
            }
              //  {
              //   name: '图文消息',
              //   href: '/app/material',
              //   onArr: ['material', 'addMaterial', 'editMaterial']
              // }
            ]
          }
        },
        {
          arr: ['appPay'],
          obj: {
            title: '收益管理',
            arr: [
              //   {
              //   name: '广告收益',
              //   href: '/appPay/balance'
              // },
              {
                name: '小程序收益',
                href: '/appPay/wechatlog'
              },
              {
                name: '配置信息',
                href: '/appPay/setUrl',
                onArr: ['setUrl', 'setPayShowString']
              }
            ]
          }
        },
        {
          arr: ['pushmsg', 'pushmsganalysis', 'pushmsgsetting'],
          obj: {
            title: '消息推送',
            arr: [{
              name: '发送消息',
              href: '/app/pushmsg'
            }, {
              name: '推送分析',
              href: '/app/pushmsganalysis'
            }, {
              name: '推送设置',
              href: '/app/pushmsgsetting'
            }]
          }
        }

        // <?php elseif(isset($this->action->id) && in_array($this->action->id, array('containermanage','containerlog')) || $this->id=='container'):?>
        //     <?php echo $this->renderPartial('//container/_containersidebar'); ?>

        // <?php elseif(isset($this->action->id) && $this->action->id=='setcol'):?>
        //     <?php echo $this->renderPartial('//app/_appbrowsersidebar'); ?>
        // <?php endif;?>
      ],
      docList: [],
      classList: [],
      select_table: 'custom',
      system_table: '_Role',
      createTableForm: {
        input_name: ''
      },
      input_note: '',
      codeList: [],
      mqttList: [],
      codePath: '/app',
      isCreateTableVisible: false,
      isCreateAIVisible: false,
      isCreateMqttVisible: false,
      aiForm: {
        name: '',
        greeting: ''
      },
      aiRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.codePath = '/' + val.path.split('/')[1]
        console.log(' this.codePath ', this.codePath, val)
      },
      immediate: true
    }
  },
  mounted() {
    console.log('appSideBar mounted')
    const path = this.$route.path
    const item = this.routeArr.find(e => {
      return e.arr.find(ee => {
        const r = path.includes('/' + ee)
        if (r) {
          console.log('path ' + path + ' includes ', '/' + ee)
        }
        return r
      })
    })
    if (item) {
      this.obj = item.obj
    } else {
      console.log('item is null', path)
    }
    this.bindListeners()
    this.fetchData()

    this.$EventBus.$on('shouldRefreshAIAppList', () => {
      this.fetchData()
    })
  },
  methods: {
    submitForm(formName) {
      if (formName === 'mqttForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            createMqttServers({
              app_id: this.appId,
              ...this.mqttServer
            }).then(res => {
              console.log('postClass', res)
              this.$message.success('创建成功')
              this.resetForm(formName)
              this.$router.push('/app/mqtt/' + this.appId + '/' + res.data.id)
              
            })
          } else {
            this.resetForm(formName)
            return false
          }
        })
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            postClass({
              app_id: this.appId,
              ...this.aiForm
            }).then(res => {
              console.log('postClass', res)
              this.$message.success('创建成功')
              this.$router.push('/ai/detail/' + this.appId + '/' + res.data.iD)
              this.resetForm(formName)
            })
          } else {
            return false
          }
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.isCreateAIVisible = false
    },
    delCloud(name, row) {
      // data-toggle="modal" data-target="#del-tab"
      console.log(' delCloud name', name, row)
      $('#delete_code_name').html(name)
      $(`#del-tab`).modal('show')
    },
    delClass(row) {
      this.$confirm('此操作将永久删除角色"' + row.name + '", 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const classId = row.iD || row.id
        delClass(classId).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$router.push('/ai/detail/' + this.appId)
        })
      }).catch(() => {
      })
    },
    onClickCloudFun(url) {
      if (this.$route.path.includes('/cloud/')) {
        this.$EventBus.$emit('onClickCloudFun', url)
      } else {
        this.$router.push(url)
      }
    },
    onClickMqtt(url) {

      this.$router.push(url)

    },
    showAppList() {
      return this.$route.path.includes('browser') || this.$route.path.includes('/app/setcol')
    },
    fetchData() {
      if (this.$route.path.includes('/ai/detail/')) {
        if (this.$store.state.data.aiAppList.length) {
          this.tmp_list = this.$store.state.data.aiAppList.map(e => {
            return {
              id: e.iD,
              name: e.name
            }
          })
          setTimeout(() => {
            this.$EventBus.$emit('sendAIAppList', this.tmp_list)
          }, 10)
        } else {
          this.loading = true
          this.$store.dispatch('data/initAIAppList').then((res) => {
            this.loading = false
            this.tmp_list = res.map(e => {
              return {
                id: e.iD,
                name: e.name
              }
            })

            this.$EventBus.$emit('sendAIAppList', this.tmp_list)
          }).catch(() => {
            this.loading = false
          })
        }

        if (this.$store.state.data.aiClassList.length) {
          this.docList = this.$store.state.data.aiClassList
          setTimeout(() => {
            this.$EventBus.$emit('sendAIClassList', this.docList)
          }, 10)
        } else {
          this.loading = true
          this.$store.dispatch('data/initAIClassList').then((res) => {
            this.loading = false
            this.docList = res
            this.$EventBus.$emit('sendAIClassList', this.docList)
          }).catch(() => {
            this.loading = false
          })
        }

        return
      }
      if (this.$store.state.data.appList.length) {
        this.tmp_list = this.$store.state.data.appList
        setTimeout(() => {
          this.$EventBus.$emit('sendAppList', this.tmp_list)
        }, 10)
      } else {
        this.loading = true
        this.$store.dispatch('data/initAppList').then((res) => {
          this.loading = false
          this.tmp_list = res
          this.$EventBus.$emit('sendAppList', this.tmp_list)
        }).catch(() => {
          this.loading = false
        })
      }
      if (this.showAppList()) {
        if (this.$store.state.data.tableList[this.appId] && this.$store.state.data.tableList[this.appId].length) {
          this.docList = this.$store.state.data.tableList[this.appId]
          setTimeout(() => {
            this.$EventBus.$emit('sendMsg', this.docList)
          }, 10)
        } else {
          this.loading = true
          this.$store.dispatch('data/initTableList', this.appId).then((res) => {
            this.loading = false
            this.docList = res
            this.$EventBus.$emit('sendMsg', this.docList)
          }).catch(() => {
            this.loading = false
          })
        }
      } else if (this.$route.path.includes('cloud') || this.$route.path.includes('Cloud')) {
        if (this.$store.state.data.cloudList[this.codePath][this.appId] && this.$store.state.data.cloudList[this.codePath][this.appId].length) {
          this.codeList = this.$store.state.data.cloudList[this.codePath][this.appId]
          setTimeout(() => {
            this.$EventBus.$emit('sendCloudMsg', this.codeList)
          }, 10)
        } else {
          this.loading = true
          this.$store.dispatch('data/initCloudList', { appId: this.appId, codePath: this.codePath }).then((res) => {
            this.loading = false
            this.codeList = res
            this.$EventBus.$emit('sendCloudMsg', this.codeList)
          }).catch(() => {
            this.loading = false
          })
        }
      } else if (this.$route.path.includes('mqtt')) {
        console.log("hello.")
        this.loading = true
        this.$store.dispatch('data/initMqtt', { appId: this.appId, codePath: this.codePath }).then((res) => {
          this.loading = false
          this.mqttList = res
          console.log(this.mqttList, "mqttList");
          this.$EventBus.$emit('sendCloudMsg', this.mqttList)
        }).catch(() => {
          this.loading = false
        })
      }

    },
    onChangeApp(priority_url) {
      if (this.$route.path.includes('/ai/detail/')) {
        window.location.href = ('/ai/detail/' + priority_url)
        return
      }
      console.log('onChangeApp', priority_url)
      if (priority_url == '') {
        //   showError('暂无此应用的任何权限')
        this.$message.error('暂无此应用的任何权限')
        window.location.reload()
      } else {
        const url = this.$route.path

        const t_data = url.split('/')
        let t_url = ''
        if (url.indexOf('browser') > -1) {
          t_url = t_data[0] + '/' + t_data[1] + '/' + t_data[2] + '/' + priority_url
          window.location.href = t_url
        } else if (url.indexOf('analysis') > -1 || url.indexOf('Analysis') > -1) {
          t_url = t_data[0] + '/' + t_data[1] + '/' + t_data[2] + '/' + priority_url
          window.location.href = t_url
        } else {
          window.location.href = priority_url
        }
      }
    },
    bindListeners() {
      setTimeout(() => {
        if (this.showAppList()) {
          // $('#div_scroll').niceScroll({
          //   cursorcolor: '#a0a0a0',
          //   cursoropacitymax: 1,
          //   touchbehavior: false,
          //   cursorwidth: '5px',
          //   cursorborder: '0',
          //   cursorborderradius: '5px'
          // })
          // $('#div_scroll').css('height', '500px')
          $('.permission-type li').click(function () {
            $(this).addClass('on').siblings().removeClass('on')
          })
        }

        // let that = this
        // $('#sel_app').on('change', function() {
        //     let priority_url = $(this).val()
        //     if (priority_url == '') {
        //         //   showError('暂无此应用的任何权限')
        //         this.$message.error('暂无此应用的任何权限')
        //         window.location.reload()
        //     } else {
        //         let url = that.$route.path
        //         console.log('url', url)

        //         let t_data = url.split('/')
        //         let t_url = ''
        //         if (url.indexOf('browser') > -1) {
        //             t_url = t_data[0] + '/' + t_data[1] + '/' + t_data[2] + '/' + $(this).val()
        //             window.location.href = t_url
        //         } else if (url.indexOf('analysis') > -1 || url.indexOf('Analysis') > -1) {
        //             t_url = t_data[0] + '/' + t_data[1] + '/' + t_data[2] + '/' + $(this).val()
        //             window.location.href = t_url
        //         } else {
        //             window.location.href = priority_url
        //         }
        //     }
        // })
      }, 100)
    },
    isOn(item) {
      const arr = item.onArr
      const path = this.$route.fullPath
      let res = false
      if (arr && arr.length) {
        if (arr[0] === '*') {
          res = path.includes('/' + item.href.split('/')[1] + '/')
        } else {
          arr.forEach(e => {
            e = '/' + item.href.split('/')[1] + '/' + e + '/'
            if (path.includes(e)) {
              res = true
            }
          })
        }
      } else {
        res = path.includes(item.href + '/')
      }
      return res
    },
    addTable() {
      this.$refs['createTableForm'].validate((valid) => {
        if (valid) {
          const data = {
            AppId: Number(this.appId)
          }
          if (this.select_table === 'custom') {
            data.TableNames = this.createTableForm.input_name
            data.Desrc = this.input_note
          } else {
            data.TableNames = this.system_table
          }
          if (data.TableNames) {
            createTable(data).then(res => {
              window.location.href = '/app/browser/' + this.appId + '/' + res.data.id
            })
          }
        } else {
          return false
        }
      })
    },

    validatorTableName(rule, value, callback) {
      if (this.select_table !== 'custom') { callback() }
      const reg = /^[a-zA-Z]([a-zA-Z0-9_]+)?$/
      const bol = reg.test(value)
      if (!value) {
        callback(new Error('请输入表名称'))
      } else if (value.length > 16) {
        callback(new Error('表名长度须在16以内'))
      } else if (!bol) {
        callback(new Error('表名称只能包含字母、数字、下划线、必须以字母开头'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style scoped>
.choose-app /deep/.el-select {
  width: 100%;
}

.choose-app /deep/.el-input__inner {
  padding: 0 8px;
  font-size: 12px;
  border-radius: 0 !important;
}
</style>

<style lang="scss">
.m-drawer {
  width: 100vw !important;
  max-width: 750px;

  .el-drawer__header {
    font-size: 16px;
    color: #333;
  }

  .el-drawer__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 0 24px 80px;
  }

  .el-drawer__body::-webkit-scrollbar {
    width: 4px;
  }

  .el-drawer__body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  .el-drawer__body::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .m-drawer-body {
    flex: 1;

    .el-form-item__content {
      display: flex;
      margin-bottom: 12px;

      .v-lable {
        flex: 0 0 11em;
        display: flex;
        flex-direction: column;
        line-height: 1.2;

        strong {
          line-height: 32px;
        }

        span {
          color: #999;
          margin-top: 4px;
        }
      }

      .f-start {
        align-items: flex-start;
        width: 100%;

        .t-message {
          color: #999;
          line-height: 1.2;
          margin-top: 4px;
        }
      }
    }
  }

  .dialog-footer,
  .m-drawer-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px;
    flex: 0 0 auto;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }

  .dialog-footer .el-button,
  .m-drawer-footer .el-button {
    margin-right: 10px;
  }
}
</style>
