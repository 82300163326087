<template>
  <div class="appLayout" :class="$route.path=='/app/list' || $route.path=='/ai/list'?'appLayout-list':''">
    <el-alert v-for="(item, index) in alertList" :key="item.id" type="success" effect="dark" @close="onCloseAlert(item, index)">
      <div slot="title" @click="onClickAlert(item, index)">
        <span :class="item.link ? 't-link':'' ">{{ item.content }}</span>
      </div>
    </el-alert>
    <div v-for="(item) in alertList" :key="item.id" class="v-alert" />
    <left />
    <div v-if="$route.path.includes('/list')" class="main">
      <router-view :key="$route.path" />
      <appFooter />
    </div>
    <template v-else>
      <appSidebar v-if="$route.path.includes('/ai/detail/')" :alert-list="alertList" />
      <sidebar v-else :style="alertList.length ? 'padding-top: 38px;' : ''" />

      <div class="main-right container">
        <router-view :key="$route.path" />
        <appFooter />
      </div>
    </template>
    <div class="alert alert-success alert-dismissable" style="display: none;">
      <button type="button" class="close" aria-hidden="true">&times;</button>
      <div id="msg_success" />
    </div>
    <div class="alert alert-danger alert-dismissable" style="display: none;">
      <button type="button" class="close" aria-hidden="true">&times;</button>
      <div id="msg_error" />
    </div>
    <!-- <script type="text/javascript" src="<?php echo Yii::app()->params['themeStaticHost'];?>/js/common.js?<?php echo Yii::app()->params['jsVersion'];?>"></script> -->
  </div>
</template>
<script>
import left from '@/components/left'
import sidebar from '@/components/sidebar'
import appFooter from '@/components/appFooter'
import appSidebar from '@/components/appSidebar'

import '@/utils/bootstrap.min.js'
import { fetchNotifyList, markReadNotify } from '@/api/backend'
import { getApp } from '@/api/app'

export default {
  name: 'Backend',
  components: { left, appFooter, sidebar, appSidebar },
  data() {
    return {
      alertList: []
    }
  },
  created() {
    // require('@/styles/base.css')
    // require('@/styles/global.css')
    // require('@/styles/datalist.css')
    // require('@/styles/responsive-nav.css')
    // require('@/styles/pay.css')
  },
  mounted() {
    $('button.close').on('click', function() {
      $(this).parent('.alert').hide()
    })

    var b = $(window).width()
    if (b > 1000) {
      $('.user-msg').hover(function() {
        $(this).children('.logout').stop().slideDown(500)
        $(this).children('.glyphicon-menu-down').addClass('rota')
      }, function() {
        $(this).children('.logout').stop().slideUp(500)
        $(this).children('.glyphicon-menu-down').removeClass('rota')
      })
    } else {
      $('.user-msg').click(function() {
        $(this).children('.logout').stop().slideToggle(500)
      })
    }

    var that = this
    $('.user-msg .logout').click(function() {
      that.$store
        .dispatch('user/logout')
        .then(() => {
          that.$router.push({
            path: '/'
          })
        })
    })

    // eslint-disable-next-line no-undef
    responsiveNav('#nav') // 响应式导航
    // 根字号设置
    var win = $(window)
    var setRootFontSize = function() {
      var ww = win.width()
      var root
      if (ww < 320) root = 20
      else if (ww >= 640 && ww < 1200) root = ww * 40 / 1200
      else if (ww >= 1200) root = 40
      $('html').css('font-size', root + 'px')
    }
    win.resize(setRootFontSize).load(setRootFontSize).resize()

    // alert监听
    $(window).scroll(function() {
      // var $pst=$(".alert").position().top;
      var $sct = $(this).scrollTop()
      var win2 = $(window).width()
      if (win2 >= 640) {
        $('.alert-idx').css({ 'top': $sct })
      } else {
        var $ht = $('.main-left').height() + 40
        $('.alert-idx').css({ 'top': $sct - $ht })
      }
    })

    $('#close_banner').click(function(event) {
      $(this).hide()
      $('html').css('background', 'none').css('paddingTop', '0')
      $('.sidenav').css('top', '0')
      $('.main-left').css('top', '0')
    })

    fetchNotifyList({
      query: 'IsShowIndex:1,IsReaded:0',
      limit: 1
    }).then(res => {
      if (res && res.data && res.data.results && res.data.results.length) {
        this.alertList = res.data.results

        const alert = res.data.results[0]
        if (alert.notifyType == 2 && alert.content.indexOf('>>升级套餐') > -1 && alert.link.lastIndexOf('applevel/') > -1) {
          const appId = alert.link.substring(alert.link.lastIndexOf('applevel/') + 9)
          getApp(appId).then((app) => {
            app = app.data
            const appLevelDeadtime = app.appLevelDeadtime * 1000
            if (appLevelDeadtime > ((new Date()).getTime() + 1000 * 60 * 60 * 24 * 28)) {
              console.log('到期时间还有4周以上')
              this.onCloseAlert(alert, 0)
            }
          })
        }
      } else {
        this.alertList = []
      }
    })
  },
  methods: {
    onClickAlert(item, index) {
      if (item.link) {
        markReadNotify([item.id]).then(res => {
          this.alertList.splice(index, 1)
          this.$EventBus.$emit('notifyNumChange')
        })
        window.location = item.link
      }
    },
    onCloseAlert(item, index) {
      markReadNotify([item.id]).then(res => {
        this.alertList.splice(index, 1)
        this.$EventBus.$emit('notifyNumChange')
      })
    }
  }
}
</script>
<style scoped>
/* <!-- <?php if($this->action->id=='list' || $this->action->id == 'getBuyProject'):?>
<link rel="stylesheet" type="text/css" href="<?php echo Yii::app()->params['themeStaticHost']; ?>/css/index.css?<?php echo Yii::app()->params['cssVersion'];?>">
<?php else:?>
<link rel="stylesheet" type="text/css" href="<?php echo Yii::app()->params['themeStaticHost']; ?>/css/datalist.css?<?php echo Yii::app()->params['cssVersion'];?>">
<link rel="stylesheet" type="text/css" href="<?php echo Yii::app()->params['themeStaticHost']; ?>/css/pay.css?<?php echo Yii::app()->params['cssVersion'];?>">
<?php endif; ?> --> */
@import '~@/styles/base.css';
@import '~@/styles/global.css';
@import '~@/styles/datalist.css';
@import '~@/styles/responsive-nav.css';
@import '~@/styles/pay.css';
@import '~@/styles/index.css';
</style>
<style>
.el-table th {
    background-color: #f3f7ff !important;
}
</style>
