<template>
  <div class="main-left">
    <div>
      <div>
        <p class="biaoti pd1">
          <b>{{ obj.title }}</b>
          <template v-if="obj.beta">
            &nbsp;<img style="display:inline" src="@/assets/images/b.png" alt="">
          </template>
        </p>
        <div v-if="obj.showHelper" class="row-helper">今天还剩{{ obj.helperNum }}条对话
          <!-- <router-link to="/helper/buy">充值</router-link> -->
        </div>
      </div>
      <ul class="subnav news-subnav">
        <li v-for="item in obj.arr" :id="item.id || ''" :key="item.href" :class="isOn(item) ? 'on' : ''">
          <router-link :to="item.id? '':item.href">
            <span>{{ item.name }}</span>
            <img v-if="item.icon" :src="item.icon" class="icon">
            <span v-if="item.newsNum || item.newsNum==0" class="badge pull-right mt15">{{ item.newsNum }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getNotifyUnreadNum } from '@/api/backend'
export default {
  data() {
    return {
      obj: {
        helperNum: '-'
      },
      routeArr: {
        'helper': {
          title: '编程助手',
          showHelper: true,
          arr: [{
            name: '编程助手',
            href: '/helper/index'
          }
          // {
          //   name: '次数购买',
          //   href: '/helper/buy'
          // }
          ]
        },
        'increment': {
          title: '增值服务',
          arr: [{
            name: '定制开发',
            href: '/increment/devlist',
            onArr: ['devlist', 'devpublish']
          }]
        },
        'finance': {
          title: '账务管理',
          arr: [{
            name: '财务统计',
            href: '/finance/index',
            onArr: ['charge', 'index', 'sms', 'storage', 'flow', 'custom']
          },
          {
            name: '交易明细',
            href: '/finance/order'
          },
          // {
          //   name: '提款管理',
          //   href: '/finance/log'
          // },
          {
            name: '发票管理',
            href: '/finance/invoice',
            onArr: ['invoice', 'invoiceApply']
          }
          ]
        },
        'shop': {
          title: '项目交易管理',
          arr: [{
            name: '购买项目',
            href: '/shop/getBuyProject',
            onArr: ['getBuyProject', 'Bdetail']
          }, {
            name: '出售项目',
            href: '/shop/getProject',
            // icon: require('@/assets/images/money.png'),
            onArr: ['getProject', 'publish', 'edit', 'det']
          }, {
            name: '交易明细',
            href: '/shop/allIncome'
          }]
        },
        'notify': {
          title: '消息管理',
          arr: [{
            name: '全部消息',
            href: '/notify/index',
            newsNum: 0
          }, {
            name: '系统消息',
            href: '/notify/index?notifyType=1',
            newsNum: 0
          }, {
            name: '配额预警',
            href: '/notify/index?notifyType=2',
            newsNum: 0
          }, {
            name: '服务升级',
            href: '/notify/index?notifyType=3',
            newsNum: 0
          }]
        },
        'workOrder': {
          title: '工单',
          arr: [{
            name: '工单列表',
            href: '/workOrder/index',
            onArr: ['index', 'detail']
          },
          {
            name: '创建工单',
            id: 'createOrder',
            href: '/workOrder/create'
          }
          ]
        },
        'account': {
          title: '账户管理',
          arr: [{
            name: '用户信息',
            href: '/account/index'
          },
          // {
          //   name: '团队管理',
          //   href: '/account/team',
          //   onArr: ['team', 'teamAdd']
          // },
          // {
          //   name: '实名认证',
          //   id: 'verifyInfo',
          //   href: '/account/info'
          // },
          // {
          //     name: '备案进度',
          //     href: '/account/icp',
          //     onArr: ['icp', 'icpAdd']
          // },
          {
            name: '通知设置',
            href: '/account/alarmsms'
          }, {
            name: '密码修改',
            href: '/account/reset'
          }, {
            name: '第三方账户',
            href: '/account/thirdparty'
          }
            // {
            //     name: '推荐用户',
            //     href: '/account/invite'
            // }
          ]
        }
        //   <?php elseif($this->id=='shop' && ($this->action->id == 'det' || $this->action->id == 'edit' || $this->action->id == 'income')):?>
        //       <?php  echo $this->renderPartial('//shop/_projectdetsidebar'); ?>

        //   <?php elseif($this->id=='shop'):?>
        //       <?php  echo $this->renderPartial('//shop/_projectsidebar'); ?>

        //   <?php elseif($this->id=='notify'):?>
        //    <?php echo $this->renderPartial('//notify/_notifysidebar'); ?>

        //   <?php elseif($this->id=='workOrder'):?>
        //    <?php  echo $this->renderPartial('//workOrder/_sidebar'); ?>

        //   <?php elseif($this->id=='account'):?>
        //    <?php echo $this->renderPartial('//account/_accountsidebar'); ?>

        //   <?php elseif($this->id=='analysis'):?>
        //    <?php echo $this->renderPartial('//analysis/_analysisleftbar'); ?>

      }
    }
  },
  mounted() {
    const path = this.$route.path.split('/')
    this.obj = this.routeArr[path[1]]
    this.bindListeners()
    if (path[1] === 'notify') {
      this.getNewsNum()
    }
    if (this.obj.showHelper) {
      this.$EventBus.$on('onChangeHelperNum', (num) => {
        this.$set(this.obj, 'helperNum', num)
      })
    }
  },

  beforeDestroy() {
    if (this.obj.showHelper) {
      this.$EventBus.$off('onClickCloudFun')
    }
  },
  methods: {
    getNewsNum() {
      getNotifyUnreadNum().then(res => {
        res = res.data
        this.obj.arr[1].newsNum = res[1] || 0
        this.obj.arr[2].newsNum = res[2] || 0
        this.obj.arr[3].newsNum = res[3] || 0
        this.obj.arr[0].newsNum = this.obj.arr[1].newsNum + this.obj.arr[2].newsNum + this.obj.arr[3].newsNum
      })
    },
    bindListeners() {
      const that = this
      setTimeout(() => {
        // 创建工单时需要绑定公众号
        $('#createOrder').click(function() {
          const openId = that.$getUserInfo().openId
          console.log(`openId`, openId)
          if (!openId) {
            that.showBindDialog()
            return false
          }
          that.$router.push('/workOrder/create')
        })
        $('#verifyInfo').click(() => {
          this.checkOpenId('/account/info')
        })
      }, 100)
    },
    checkOpenId(href) {
      const openId = this.$getUserInfo().openId
      if (!openId) {
        this.showBindDialog()
        return false
      }
      location.href = href
    },
    showBindDialog() {
      // $('#OrderModal').modal('show')
      this.$confirm('尊敬的Bmob用户，为了更好的提高工单的处理效率，请先绑定Bmob后端云官方微信公众号（微信号：bmober），绑定后可以随时了解工单进度，及时解决问题。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/account/thirdparty')
      }).catch(() => {})
    },
    isOn(item) {
      const arr = item.onArr
      const path = this.$route.fullPath
      let res = false
      if (arr) {
        arr.forEach(e => {
          e = '/' + item.href.split('/')[1] + '/' + e
          if (path.includes(e)) {
            res = true
          }
        })
      } else {
        res = path == item.href
      }
      return res
    }
  }
}
</script>
<style scoped>
.row-helper {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px;
}
</style>
