import request from '@/utils/request'

export function checkLogin() {
    return request({
        url: '/backend/notify/unread_num?for_check_login=1',
        method: 'get'
    })
}

// 收益->小程序收益
export function fetchXiaoPayList(params) {
    return request({
        url: '/backend/xiaopay',
        method: 'get',
        params
    })
}

export function createXiaoPay(data) {
    return request({
        url: 'backend/xiaopay',
        method: 'post',
        data
    })
}

export function getXiaoPay(id) {
    return request({
        url: '/backend/xiaopay/' + id,
        method: 'get'
    })
}

export function updateXiaoPay(id, data) {
    return request({
        url: '/backend/xiaopay/' + id,
        method: 'put',
        data
    })
}

export function delXiaoPay(id) {
    return request({
        url: '/backend/xiaopay/' + id,
        method: 'delete'
    })
}

// 工单
export function fetchWorkOrderList(params) {
    return request({
        url: '/backend/work_order',
        method: 'get',
        params
    })
}

export function createWorkOrder(data) {
    return request({
        url: 'backend/work_order',
        method: 'post',
        data
    })
}

export function getWorkOrder(id) {
    return request({
        url: '/backend/work_order/' + id,
        method: 'get'
    })
}

export function updateWorkOrder(id, params, data) {
    return request({
        url: '/backend/work_order/' + id,
        method: 'put',
        params,
        data
    })
}

export function delWorkOrder(id) {
    return request({
        url: '/backend/work_order/' + id,
        method: 'delete'
    })
}

export function replyWorkOrder(data) {
    return request({
        url: '/backend/work_order/reply',
        method: 'post',
        data
    })
}

export function fetchTop(limit = 25) {
    return request({
        url: '/backend/work_order/repository/top',
        method: 'get',
        params: {
            limit
        }
    })
}

export function searchWorkOrder(params) {
    return request({
        url: '/backend/work_order/repository/search',
        method: 'get',
        params
    })
}

// 消息管理
export function fetchNotifyList(params) {
    return request({
        url: '/backend/notify',
        method: 'get',
        params
    })
}

export function delNotify(ids) {
    return request({
        url: '/backend/notify/delete',
        method: 'delete',
        params: {
            ids: ids.join(',')
        }
    })
}

export function markReadNotify(ids) {
    return request({
        url: '/backend/notify/mark_read',
        method: 'put',
        params: { ids: ids.join(',') },
        data: {
            'IsReaded': 1
        }
    })
}

export function getNotifyUnreadNum() {
    return request({
        url: '/backend/notify/unread_num',
        method: 'get'
    })
}

// 收益模块
export function getIncomeCallbackUrl(appId) {
    return request({
        url: `/backend/income/${appId}/callbackUrl`,
        method: 'get'
    })
}
export function setIncomeCallbackUrl(appId, NotifyUrl, ReturnUrl) {
    return request({
        url: `/backend/income/${appId}/callbackUrl`,
        method: 'post',
        data: {
            NotifyUrl,
            ReturnUrl
        }
    })
}

// 1.升级页面中所有获取金额统一使用的方法（除了存储变更外）
// curl -X GET 'http://127.0.0.1:8080/v1/backend/upgrade/200877/upgadeMoney' \
//   -H 'Is-Jeff-Test: true' \
//   -H 'Cookie: wechat-token=2e345d5b69860b4f489d8b4357f5cfb4; Admin-Token=93334' \
//   --compressed \
//   --insecure
// 传入的参数：
// buyType: 付费的类型（0-套餐升级，1-短信服务 5-存储购买  6-流量购买,7-云逻辑续费,
//          10-云数据库升级,12-海外节点加速,13-文件二级域名,14-文件FTP,15-api购买,17-数据监听,19-email购买）
// appLevel: 需要升级到的应用等级，当是应用套餐类型时需要
// shijian: 时长，单位为月或年
// amount: 按量购买里的量
// 返回的参数：
// deadtime: "2022-01-06 00:00:00" //过期时间
// money: 100  //应付金额里的总额
// price: 50   //应付金额里的每月或年的金额
// oriAppLevel: 3  //当前的应用等级，当是应用套餐类型时返回
export function getUpgadeMoney(appId, params) {
    return request({
        url: `/backend/upgrade/${appId}/upgadeMoney`,
        method: 'get',
        params
    })
}

// 2.升级页面中所有“支付宝购买”和“余额购买”使用的方法：
// curl -X GET 'http://127.0.0.1:8080/v1/backend/upgrade/200877/payMoney' \
//   -H 'Is-Jeff-Test: true' \
//   -H 'Cookie: wechat-token=2e345d5b69860b4f489d8b4357f5cfb4; Admin-Token=93334' \
//   --compressed \
//   --insecure
// 传入的参数：
// buyType: 付费的类型（0-套餐升级，1-短信服务 5-存储购买  6-流量购买,7-云逻辑续费,
//          10-云数据库升级,12-海外节点加速,13-文件二级域名,14-文件FTP,15-api购买,17-数据监听,19-email购买）
// payType: 支付类型，1-支付宝 2-余额
// appLevel: 需要升级到的应用等级，当是应用套餐类型时需要
// shijian: 时长，单位为月或年,套餐升级时按年或月购买的时长
// amount: 按量购买里的量
// pass:用余额购买时需要传入开发者的登录密码
// 返回的参数：
// 支付宝购买：
// {"code":200,"data":"https://openapi.alipay.com/gateway.do?alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6\u0026app_cert_sn=7ff99638467dcf9588592b51bc49b389\u0026app_id=2021002121679215\u0026biz_content=%7B%22subject%22%3A%22%E5%90%8E%E7%AB%AF-%E4%BA%91%E5%87%BD%E6%95%B0%E7%BB%AD%E8%B4%B91%E4%B8%AA%E6%9C%8850%E5%85%83%22%2C%22out_trade_no%22%3A%22e30cbab6f4549e3f60941294ef72cfe7%22%2C%22total_amount%22%3A%220.01%22%2C%22product_code%22%3A%22FAST_INSTANT_TRADE_PAY%22%7D\u0026charset=utf-8\u0026format=JSON\u0026method=alipay.trade.page.pay\u0026notify_url=http%3A%2F%2Fwebsite.bmobapp.com%2Fv1%2Ffront%2Fnotify%2Falipay%2Fe30cbab6f4549e3f60941294ef72cfe7\u0026return_url=http%3A%2F%2Fwebsite.bmobapp.com%2Fupgrade%2Findex%2F200877\u0026sign=iShQMe1XXaZ6NiND65F%2BOBRIHKrWrYLNhpM1jJpxpu5k21yD3L8sC6BuVf8FONQS%2FovTVLEkHahKpxeRzLtEhR9m6Tu7KdyHqGDD3svrrQ4qRYrUstTRcym2gG4PFNqcJE%2FygO5XaGNjJnTcVW74CHJLebHSifKbOWBk0kKSO03XpqKhGSTymKwFTQWqWjeFNcO8NUBCiYMY%2FemI1ckYqcji5NUACMohdWux00W7hGKWTPubgJao1cnZtwK%2FZSsiSU7fhdXvYkmeKfv%2BVG5a%2FiDHZ%2BLhEI4w83rITujTH3uFRx8tGSe63GjRVgOJSr2dNhNKAiPmtDN7B2lYk9YwdA%3D%3D\u0026sign_type=RSA2\u0026timestamp=2021-09-22+17%3A12%3A46\u0026version=1.0","msg":"success"}
// 余额购买：
// {"code":200,"data":"余额购买成功","msg":"success"}
export function payMoney(that, appId, money, params) {
//     if (params.payType === 1 && money > 1200) {
//         // 支付宝在支付金额大于1200元时，就强制只能通过银行转账
//         that.$alert(`<div style="color: #b57849;padding:0 20px">
//   <h3 style="width:12em;font-weight:bold;padding-bottom:3px;border-bottom: 2px solid #eee;">亲爱的Bmob会员您好：</h3>
//   <p>我司将对线上支付渠道进行维护调整，近段时间所有服务购买，套餐续费都进行银行卡充值，我们确认收款后会将充值金额打入发送的Bmob账户内，操作完成后，再通过账户余额购买相关服务。由此给您带来的不便，敬请谅解！</p>
//   <div style="border-bottom: 1px solid #ccc;"></div>
//   <h3 style="font-weight:bold;">转账账户：</h3>
//   <p>户名：元素（深圳）软件有限公司 </p>
//   <p>开户行：中国工商银行股份有限公司深圳高新园南区支行</p>
//   <p>账号：4000027209200870260</p>
//   <p>注：<b>不支持使用支付宝或微信进行转账</b>，请使用银行APP进行转账操作，转账后请把<b>回执截图</b>和Bmob<b>账户邮箱</b>发到指定邮箱，magic@bmobcloud.com。工作人员将会在12小时内完成金额入账，请提前做好打款安排。如有紧急情况，请联系客服QQ：2967459363</p>
// </div>`, '提示', {
//             dangerouslyUseHTMLString: true,
//             customClass: 'hint-pay-dialog'
//         })
//         return
//     }
    return request({
        url: `/backend/upgrade/${appId}/payMoney`,
        method: 'get',
        params
    })
}