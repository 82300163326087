import request from '@/utils/request'

import store from '@/store'

export function fetchTableList(id) {
  return request({
    url: '/backend/table',
    method: 'get',
    params: {
      query: 'AppId:' + id,
      limit: 1000
    }
  })
}

// 字段排序
export function sortTableField(table, id, field) {
  field = field.filter(e => {
    return !['objectId', 'updatedAt', 'createdAt', 'ACL'].includes(e)
  })
  return request({
    url: '/backend/field/sort/' + id,
    method: 'post',
    data: {
      table,
      field
    }
  })
}

export function createTable(data) {
  store.dispatch('data/resetTableList')
  return request({
    url: '/backend/table',
    method: 'post',
    data
  })
}

export function getTable(id) {
  return request({
    url: '/backend/table/' + id,
    method: 'get'
  })
}

export function updateTable(id, data) {
  store.dispatch('data/resetTableList')
  return request({
    url: '/backend/table/' + id,
    method: 'put',
    data
  })
}

export function delTable(id) {
  store.dispatch('data/resetTableList')
  return request({
    url: '/backend/table/' + id,
    method: 'delete'
  })
}

export function getStructure(appid, tName, tid) {
  return request({
    // url: '/backend/table/structure/' + id,
    url: `/backend/field/${appid}/${tName}`,
    method: 'get',
    params: {
      query: `DocId:${tid}`,
      limit: 10000,
      sortby: 'SortId',
      order: 'asc'
    }
  })
}

export function createColumn(data) {
  return request({
    url: '/backend/field',
    method: 'post',
    data
  })
}

export function updateColumn(id, data) {
  return request({
    url: '/backend/field/' + id,
    method: 'put',
    data
  })
}

export function cleanTable(tName) {
  return request({
    url: '/backend/table/tableData/' + tName,
    method: 'delete'
  })
}

export function delColumn(id) {
  return request({
    url: '/backend/field/' + id,
    method: 'delete'
  })
}
export function updateACL(tableName, acl) {
  return request({
    url: `/backend/table/updateACL/${tableName}`,
    method: 'put',
    data: {
      acl
    }
  })
}
export function exportTable(data) {
  return request({
    url: '/backend/table/export',
    method: 'post',
    data
  })
}
export function importTable(data) {
  return request({
    url: '/backend/table/import',
    method: 'post',
    data
  })
}

// 唯一键
export function getTableIndex(tableName) {
  return request({
    url: `/backend/table/tableIndex/${tableName}`,
    method: 'get'
  })
}
export function setTableIndex(tableName, data) {
  return request({
    url: `/backend/table/tableIndex/${tableName}`,
    method: 'post',
    data
  })
}
export function delTableIndex(tableName) {
  return request({
    url: `/backend/table/tableIndex/${tableName}`,
    method: 'delete'
  })
}

// 隐藏列
export function getWebHiddenField(tableName) {
  return request({
    url: `/backend/table/webHiddenField/${tableName}`,
    method: 'get'
  })
}
export function setWebHiddenField(tableName, data) {
  return request({
    url: `/backend/table/webHiddenField/${tableName}`,
    method: 'put',
    data
  })
}

// 复制表
export function copyTable(id, data) {
  return request({
    url: `/backend/table/table/${id}`,
    method: 'post',
    data
  })
}

// table/data >>>>>>>
export function fetchTableData(id, tName, params) {
  return request({
    url: `/backend/table/data/${id}/${tName}`,
    method: 'get',
    params
  })
}

export function addRow(id, tName, data) {
  return request({
    url: `/backend/table/data/${id}/${tName}`,
    method: 'post',
    data
  })
}

export function getRow(id, tName, objectId) {
  return request({
    url: `/backend/table/data/${id}/${tName}/${objectId}`,
    method: 'get'
  })
}

export function updateRow(id, tName, objectId, data) {
  return request({
    url: `/backend/table/data/${id}/${tName}/${objectId}`,
    method: 'put',
    data
  })
}

export function delRow(id, tName, objectId) {
  return request({
    url: `/backend/table/data/${id}/${tName}/${objectId}`,
    method: 'delete'
  })
}
